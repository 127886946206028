import React, { useEffect } from "react"
import "./BlogPost.scss"
import { parseHTML } from "../../helpers/parseHTML"
import ReadTime from "../ReadTime/ReadTime"
import YoutubePlayer from "../YouTubePlayer/YoutubePlayer"
import { slugify } from "../../helpers/helpers"
import { GatsbyImage } from "gatsby-plugin-image"

const noop = () => {}

const BlogPost = (data) => {
  useEffect(() => {
    document.querySelectorAll(".blog-post-content a").forEach(function (node) {
      if (!node) {
        return
      }

      const ga = window["ga"] || noop

      node.addEventListener("click", function () {
        const title = node.innerHTML
        ga("send", "event", "outbound", "click", title, {
          transport: "beacon",
        })
      })
    })
  }, [])

  const {
    lang,
    url,
    urlName,
    backgroundImage,
    tags,
    content,
    title,
    publishDate,
    author,
    realtedposts,
    excludeReadtime,
  } = data
  const readTimeText =
    lang && (lang === "pl" ? "Czas czytania: " : "Reading time: ")

  return (
    <div className="blog-post">
      <div className="blog-post-container">
        <header>
          <h1 className="blog-post-title">{title}</h1>
          {tags && (
            <div>
              {tags.map((tag) => (
                <a
                  key={Math.random() + new Date().toDateString()}
                  href={`/${data.lang}/${urlName}/category/${slugify(
                    tag.name
                  )}/1/`}
                  className="post-tag"
                >
                  {tag.name}
                </a>
              ))}
            </div>
          )}
          {publishDate && <span className="blog-post-date">{publishDate}</span>}
        </header>
        {!excludeReadtime && (
          <div>
            {readTimeText}
            <ReadTime content={content} />
          </div>
        )}
        <a href={url}>
          <GatsbyImage
            className="blog-post-image"
            alt={backgroundImage.alt ? backgroundImage.alt : ""}
            image={backgroundImage.gatsbyImageData}
          />
        </a>
        <div className="blog-post-content">
          <div>{content && parseHTML(content)}</div>
          {!!author ? (
            <>
              <hr className="blog-line" />
              <div className="author-information">
                <GatsbyImage
                  alt={author.alt ? author.alt : author.fullName}
                  image={author.avatar.gatsbyImageData}
                />
                <div className="author-information-description">
                  <p className="author">{author.fullName}</p>
                  {author.role && (
                    <p className="author-information-role">{author.role}</p>
                  )}
                  {author.bio && parseHTML(author.bio)}
                </div>
              </div>
            </>
          ) : null}
          {realtedposts ? (
            <div className="related-posts">{parseHTML(realtedposts)}</div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default BlogPost
