import * as React from "react";
import { FC, useEffect, useState } from "react";
import './Accordion.scss';
import { IPropertyReference } from "../../helpers/interfaces";
import { PlanCardTypes } from "../PricingPlanCard/PricingPlanCard";
import Markdown from "react-markdown";

interface IAccordionProps {
  property: IPropertyReference;
}

const Accordion: FC<IAccordionProps> = ({ property }) => {
  const [isFolded, setFold] = useState<boolean>(true);

  useEffect(() => {
    property.key.includes('unfolded') && setFold(false);
  }, []);

  const toggleAccordion = (): void => {
    setFold(!isFolded);
  };

  const getDescriptionArray = () => {
    return Object.entries(property).filter(e => {
      return e[0].includes('value') && e[1] && e[0] !== 'value'
        || e[1] && e[1].toLowerCase().includes(PlanCardTypes.PREMIUM)
        || e[1] && e[1].toLowerCase().includes(PlanCardTypes.STANDARD);
    });
  };

  const getPlanTypeFromText = (text: string): string => {
    switch (text.toLowerCase()) {
      case PlanCardTypes.PREMIUM:
        return 'premium-title';
      case PlanCardTypes.STANDARD:
        return 'standard-title';
      default:
        return null;
    }
  };

  const renderDetailedDescription = (): JSX.Element[] => {
    return getDescriptionArray().map((e, idx) => {
      const descriptionValue = e[1];
      const title = descriptionValue.split('-')[0];
      const content = descriptionValue.split('-')[1];
      const value = e[1];

      return <div key={ idx }>
        <p className={ getPlanTypeFromText(value) }><strong>{ title }</strong></p>
        <p>{ content }</p>
      </div>;
    });
  };

  return (
    <div className="accordion-wrapper">
      <div className={ `accordion-header${ !isFolded ? '-active' : '' }` } onClick={ toggleAccordion }>
        <div className="accordion-title-wrapper">{ property.value }</div>
        <div className="accordion-icon"> { isFolded ? '+' : '-' }</div>
      </div>

      { !isFolded && <div className="accordion-description">
        { property.content ? <Markdown children={ property.content }/> : renderDetailedDescription() }
      </div> }
    </div>
  );
};

export default Accordion;
