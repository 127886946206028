import * as React from "react";
import './PricingPlanCard.scss'
import { IPropertyReference } from "../../helpers/interfaces"

interface IPricingPlanCardProps {
  plan: IPropertyReference,
  type: PlanCardTypes;
  toggleModal?(): void;
  labels?: string[];
}

const renderBenefitState = (benefit):JSX.Element => {
  switch (benefit.trim()){
    case 'include': return <div className='tick'/>
    case 'exclude': return <div className='cross'/>
    default: return benefit;
  }

}

const getBenefits = (plan, labels): string[][] => {
  const benefitsValues = plan.content ? plan.content.split(',') : [];
  const benefitMap = new Map();

  for(let i = 0; i < benefitsValues.length; i++){
    benefitMap.set(labels[i], benefitsValues[i]);
  }

  return Array.from(benefitMap);
}

export const PricingPlanCard = ({ plan, toggleModal, labels, type }: IPricingPlanCardProps): JSX.Element => {
  const isComingSoonPlan = type === PlanCardTypes.COMING_SOON;
  const isEnterprisePlan = type === PlanCardTypes.ENTER_PRICE;
  const isPremiumPlan = type === PlanCardTypes.PREMIUM;
  const isStandardPlan = type === PlanCardTypes.STANDARD;

  const priceAmount = plan.value3 && plan.value3.split('/') ? plan.value3.split('/')[0]: plan.value3;
  const pricePeriod = plan.value3 && plan.value3.split('/') ? plan.value3.split('/')[1]: '';

  const renderPlanButton = () => {
    switch (type) {
      case PlanCardTypes.COMING_SOON: {
        return <a href={`mailto:${plan.value7}`} className="btn plan-btn">{plan.value4}</a>
      }
      case PlanCardTypes.ENTER_PRICE: {
        return <div>
          <a href="/#" className="btn plan-btn" onClick={toggleModal}>{plan.value4}</a>
          {<div className='alternative-contact-info'>
            <span>{plan.value5}</span>
            <span className='contact-mail'><a href={`mailto:${plan.value6}`}>{plan.value6}</a></span>
          </div>}
        </div>
      }
      case PlanCardTypes.PREMIUM: {
        return  <a href="https://app.pushpushgo.com/lite/register/premium" className="btn plan-btn" onClick={toggleModal && toggleModal}>{plan.value4}</a>
      }
      case PlanCardTypes.STANDARD: {
        return <a href="https://app.pushpushgo.com/lite/register/standard" className="btn plan-btn" onClick={toggleModal && toggleModal}>{plan.value4}</a>
      }
    }
  }
  return (
    <div className={`plan-wrapper ${ type }`} key={plan.key}>
      {plan.image && <div className="plan-icon">
        <img src={plan.image.url}  alt=''/>
      </div>}

      {isPremiumPlan && <div className={'best-seller'} />}
      <div className="price-wrapper">
        <span className='price-title'> {plan.value2}</span>
        <span className='price-amount'> {priceAmount}</span>
        {(isStandardPlan || isPremiumPlan) && <span className='plan-period'> / {pricePeriod} </span>}
      </div>

      <div className="plan-title">
        {plan.value}
      </div>


      {isComingSoonPlan
      ? <div className='coming-soon-info-wrapper'>
          {plan.value5}
          <p>{plan.value6}</p>
          <span className='contact-mail'><a href={`mailto:${plan.value7}`}>{plan.value7}</a></span>
      </div>
      : <div className='plan-benefits-wrapper'>
          <table>
            <tbody>
            {getBenefits(plan, labels).map((benefit:string[]) => {
              const label = benefit[0]
              const value = benefit[1]
              return <tr className='plan-benefit' key={label}>
                <td data-label={label}><div className='benefit-value'>
                  {renderBenefitState(value)}
                </div></td>
              </tr>
            })}
            </tbody>
          </table>
        </div>
      }

      <div className="plan-footer">
        {renderPlanButton()}
      </div>
    </div>
  )
};

export enum PlanCardTypes {
  ENTER_PRICE = 'enterPrice',
  COMING_SOON = 'comingSoon',
  STANDARD = 'standard',
  PREMIUM = 'premium',
}
