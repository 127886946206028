import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./BenefitsPartnersList.scss";

interface IBenefitsPartnersListProps {
  propertyReference: IPropertyReference[];
}

const BenefitsPartnersList: React.FC<IBenefitsPartnersListProps> = ({ propertyReference }) => {
  const partnersList = filterByKey(propertyReference, "benefits-partners");

  const partners = partnersList.map((item: IPropertyReference, id: number) => {
    const canDisplayPartner = item.image && item.value && item.content;

    return canDisplayPartner && <li key={ id } className="benefits-partners__item">
      <div className="benefits-partners__image">
        <GatsbyImage alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) }/>
      </div>
      <p className="benefits-partners__content">{ item.value }</p>
      <a href={ item.value2 } className="benefits-partners__more-info">{ item.content }</a>
    </li>;
  });

  return <section className="benefits-partners">
    <ul className="benefits-partners__items">{ partners }</ul>
  </section>;
};

export default BenefitsPartnersList;
