import React, { useState } from "react"
import "./RequestContact.scss"
import ContactBackground from "../../assets/images/contact-background.svg"
import SecondBackground from "../../assets/images/we-cooperate-background.svg"
import { Formik, Form, Field, ErrorMessage } from "formik"
import PhoneInput from "react-phone-number-input"
import PartnersContainer from "../PartnersContainer/PartnersContainer"
import { findByKey } from "../../helpers/helpers"
import { IPropertyReference } from "../../helpers/interfaces"
import ReactMarkdown from "react-markdown"
import * as Yup from "yup"

const encode = (data): string => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

interface Errors {
  email?: string
  companyWebsite?: string
}

const getFormDataFromPropertyReference = (
  propertyReference: IPropertyReference[]
) => {
  const formLabels = findByKey(propertyReference, "form")
  const formValidators = findByKey(propertyReference, "validators")
  const formPlaceholders = findByKey(propertyReference, "placeholders")

  enum Validators {
    value5 = "email",
    value7 = "companyWebsite",
    value8 = "companyWebsite-should-have-protocol",
    value9 = "companyWebsite-empty",
    value10 = "email-empty",
  }

  enum FieldsMapping {
    value2 = "name",
    value3 = "lastName",
    value4 = "phoneNumber",
    value5 = "email",
    value6 = "companyName",
    value7 = "companyWebsite",
  }
  const fields = {} as {
    [key in FieldsMapping]: {
      label: string
      placeholder: string
    }
  }

  Object.entries(FieldsMapping).forEach(([key, value]) => {
    fields[value] = {
      label: formLabels[key],
      placeholder: formPlaceholders[key],
    }
  })

  const validators = {} as {
    [key in Validators]: string
  }

  Object.entries(Validators).forEach(([key, value]) => {
    validators[value] = formValidators[key]
  })

  return { fields, validators }
}

const RequestContact = ({
  propertyReference,
}: {
  propertyReference: IPropertyReference[]
}) => {
  const [isFormSubmited, setFormSubmit] = useState(false)
  const [phoneNumberValue, setPhoneNumberValue] = useState("")

  const { fields, validators } = getFormDataFromPropertyReference(
    propertyReference
  )
  const formTitle = findByKey(propertyReference, "form").value
  const dataInfo = findByKey(propertyReference, "form").content

  const {
    value: submitText,
    content: submitMessage,
    value2: errorMessage,
  } = findByKey(propertyReference, "submit")
  return (
    <PartnersContainer>
      <div className="contact__container" id="request-contact">
        <h2 className="contact__title">{formTitle}</h2>
        <div className="contact__image">
          <ContactBackground />
        </div>
        <div className="contact__form">
          <Formik
            initialValues={{
              name: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              companyName: "",
              companyWebsite: "",
            }}
            initialStatus={{
              success: false,
              error: false,
            }}
            onSubmit={(values, actions) => {
              values.phoneNumber = phoneNumberValue
              values.companyWebsite = values.companyWebsite.trim()

              fetch("/en", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "partners-form-data", ...values }),
              })
                .then((res) => {
                  if (!res.ok) {
                    return Promise.reject(res)
                  }

                  setPhoneNumberValue("")
                  actions.resetForm()

                  actions.setStatus({
                    success: true,
                    error: false,
                  })
                })
                .catch((er) => {
                  actions.setStatus({
                    success: false,
                    error: true,
                  })
                  console.error(er)
                })
                .finally(() => actions.setSubmitting(false))
            }}
            validate={async (values) => {
              const errors: Errors = {}

              const emailValidation = Yup.string()
                .required(validators["email-empty"])
                .email(validators["email"])

              try {
                await emailValidation.validate(values.email)
              } catch (e) {
                errors.email = e.message
              }

              const companyWebsiteValidation = Yup.string()
                .required(validators["companyWebsite-empty"])
                .test(
                  "have-protocol",
                  validators["companyWebsite-should-have-protocol"],
                  (value) =>
                    ["https://", "http://"].some((protocol) =>
                      value.startsWith(protocol)
                    )
                )
                .url(validators["companyWebsite"])

              try {
                await companyWebsiteValidation.validate(values.companyWebsite)
              } catch (e) {
                errors.companyWebsite = e.message
              }

              return errors
            }}
          >
            {({ isSubmitting, status }) => (
              <Form name="partners-form-data" data-netlify={true}>
                <label htmlFor="name">{fields.name.label} </label>
                <Field
                  name="name"
                  className="input-field"
                  placeholder={fields.name.placeholder}
                />
                <span className="error-message">
                  <ErrorMessage name="name" />
                </span>

                <label htmlFor="lastName">{fields.lastName.label} </label>
                <Field
                  name="lastName"
                  className="input-field"
                  placeholder={fields.lastName.placeholder}
                />
                <span className="error-message">
                  <ErrorMessage name="lastName" />
                </span>

                <label htmlFor="phoneNumber">{fields.phoneNumber.label} </label>
                <PhoneInput
                  name="phoneNumber"
                  placeholder={fields.phoneNumber.placeholder}
                  value={phoneNumberValue}
                  onChange={setPhoneNumberValue}
                  className="custom-phone-input"
                />

                <label htmlFor="email">{fields.email.label}</label>
                <Field
                  type="email"
                  name="email"
                  className="input-field"
                  placeholder={fields.email.placeholder}
                  required
                />
                <span className="error-message">
                  <ErrorMessage name="email" />
                </span>

                <label htmlFor="companyName">{fields.companyName.label} </label>
                <Field
                  name="companyName"
                  className="input-field"
                  placeholder={fields.companyName.placeholder}
                />
                <span className="error-message">
                  <ErrorMessage name="companyName" />
                </span>

                <label htmlFor="companyWebsite">
                  {fields.companyWebsite.label}
                </label>
                <Field
                  name="companyWebsite"
                  className="input-field"
                  placeholder={fields.companyWebsite.placeholder}
                  required
                />
                <span className="error-message">
                  <ErrorMessage name="companyWebsite" />
                </span>
                <div className="submit-message__wrapper">
                  {status.success ? (
                    <span className="submit-message">{submitMessage}</span>
                  ) : null}
                  {status.error ? (
                    <span className="submit-message submit-message--error">
                      {errorMessage}
                    </span>
                  ) : null}
                </div>

                <button type="submit" disabled={isSubmitting}>
                  {submitText}
                </button>
              </Form>
            )}
          </Formik>
          <ReactMarkdown className="data-info" linkTarget="_blank">
            {dataInfo}
          </ReactMarkdown>
        </div>
        <div className="contact__image contact__image--second">
          <SecondBackground className="we-cooperate" />
        </div>
      </div>
    </PartnersContainer>
  )
}

export default RequestContact
