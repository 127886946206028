import * as React from "react";
import { useEffect, useRef } from "react";
import "./PopUp.scss";

interface IPopUpProps {
  show: boolean;
  close: () => void;
  closePopUpClickOutside: boolean;
  title: string;
  content?: string;
}

const PopUp = ({ show, close, closePopUpClickOutside, title, content }: IPopUpProps) => {
  const popUpRef = useRef<HTMLDivElement>(null);

  const handleClosePopUp = (e): void => {
    if (popUpRef.current && !popUpRef.current.contains(e.target)) {
      close();
    }
  };

  useEffect(() => {
    if(closePopUpClickOutside) {
      document.addEventListener("mousedown", handleClosePopUp);

      return () => {
        document.removeEventListener("mousedown", handleClosePopUp);
      };
    }
  }, [closePopUpClickOutside]);

  return show && <div className="pop-up">
    <div ref={ popUpRef } className="pop-up__box">
      <div onClick={ close } className="pop-up__close-icon-box">
        <span className="pop-up__close-icon">&#10005;</span>
      </div>
      <h3 className="pop-up__title">{ title }</h3>
      { content && <p className="pop-up__content">{ content }</p> }
    </div>
  </div>;
};

export default PopUp;
