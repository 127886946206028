import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./EBookSummary.scss";

interface IEBookSummaryProps {
  propertyReference: IPropertyReference[];
}

const EBookSummary: React.FC<IEBookSummaryProps> = ({ propertyReference }) => {

  const summaryTitle = findByKey(propertyReference, "ebook-summary-title").value;
  const summaryItemsList = filterByKey(propertyReference, "ebook-summary-items");

  const summaryItems = summaryItemsList.map((item: IPropertyReference, id: number): JSX.Element => {
    return item.image && item.value && item.content && <div key={ id } className="ebook-summary__item">
      <div className="ebook-summary__item-image">
        <GatsbyImage alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) }/>
      </div>
      <p className="ebook-summary__item-title">{ item.value }</p>
      <p className="ebook-summary__item-subtitle">{ item.content }</p>
    </div>;

  });

  return <section className="ebook-summary">
    <div className="ebook-summary__wrapper">
      { summaryTitle && <h3 className="ebook-summary__title">{ summaryTitle }</h3> }
      { summaryItems && <div className="ebook-summary__items">{ summaryItems }</div> }
    </div>
  </section>;
};

export default EBookSummary;
