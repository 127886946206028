import * as React from "react";
import { useEffect } from "react";
import "./Layout.scss";
import { SEOMetaTags } from "../SEOMetaTags/SEOMetaTags.js";
import { IPropertyReference } from "../../helpers/interfaces";

interface ILayout {
  children: IChild[];
  lang: string;
  alternateLink?: string;
  alternateLocale?: string;
}

interface IChild {
  props: IChildProps;
}

interface IChildProps {
  componentReference: { name: string };
  propertyReference: IPropertyReference[];
}

const Layout = ({ children, lang, alternateLocale, alternateLink }: ILayout): JSX.Element => {

  useEffect((): void => {
    function getCookie(name: string): string {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
    }

    if (window && window["ga"]) {
      window["ga"]("set", "userId", getCookie("ppgUUID"));
    }

    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  return (
    <>
      <SEOMetaTags lang={lang}
                   altHref={{ alternateLink, alternateLocale }}/>
      {children}
    </>
  );


};

export default Layout;
