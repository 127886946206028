import React from "react";
import "./NotFoundContent.scss";

const NotFoundContent = (props) => {

  const notFoundContent = props.propertyReference.find(el => el.key === 'title');

  return (
    <section className="not-found-page-section">
      <div className="content-limiter not-found-page-container">
        <h1>{notFoundContent && notFoundContent.value}</h1>
        <p>{notFoundContent && notFoundContent.value2}</p>
        <p>{notFoundContent && notFoundContent.value3}</p>

      </div>
    </section>
  )}

export default NotFoundContent;




