import * as React from "react";
import "./BackToTopSign.scss";

const BackToTopSign = (): JSX.Element => {

  const backToTop = () => {
    window && window.scroll(0, 0)
  }

  const handleOnKeyDown = e => {
    if (e.keyCode === 13) {
      backToTop();
    }
  }

  return <div className="back-to-top" role={"button"} tabIndex={0} onKeyDown={handleOnKeyDown} onClick={backToTop}>
    <img src="https://www.datocms-assets.com/11168/1560768183-up.png" alt="Back to top"/>
  </div>
}

export default BackToTopSign;
