import React from "react";
import "./FeaturesSlider.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Subtitle from "../Subtitle/Subtitle";
import Img from "gatsby-image"

const FeaturesSlider = (props) => {

  const icons = [props.PushIcon1, props.PushIcon2, props.PushIcon3, props.PushIcon4, props.PushIcon5]
  const features = props.propertyReference.filter(el => el.key !== 'subtitle');
  const subtitleData = props.propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const color = subtitleData && subtitleData.value2;


  return (
    <section className="features-slider-section">
      <Subtitle text={subtitle} color={color}/>
      <div className="content-limiter features-slider-container">
        <Tabs className="tabs-container">
          <TabList className="features-type">
            {features.map((feature) => {
              return <Tab selectedClassName="active-tab" className="feature-type" key={Math.random() + new Date().toDateString()}>{feature.value2}</Tab>
            })}
          </TabList>
          <div className="features-slider">
            {features.map((feature, idx) => {
              return <TabPanel key={Math.random() + new Date().toDateString()}>
                <div className="single-feature" >
                  <div className="slider-image">
                    <div className="fake-push">
                      <div className="fake-push_icon" >
                        <Img fluid={icons[idx]} alt={''} fadeIn={false}/>
                      </div>
                      <div className="fake-push_content">
                        <div className="fake-push_title">
                          {feature.value3}
                        </div>
                        <div className="fake-push_body">
                          {feature.value4}
                        </div>
                        <div className="fake-push_domain">
                          pushpushgo.com
                        </div>
                      </div>
                    </div>
                    <StaticImage className="browser-image" alt={''} layout={'constrained'} src={'../../assets/images/browser.png'} width={520} />
                  </div>
                  <div className="slider-content">
                    <h2>{feature.value}</h2>
                    <p>{feature.content}</p>
                    {feature.value5 && <a href={feature.value6}>{feature.value5}</a>}
                  </div>
                </div>
              </TabPanel>
            })}
          </div>
        </Tabs>
      </div>
    </section>
  )};

export default FeaturesSlider;
