import * as React from "react";
import "./BasicMenu.scss";
import { StaticImage } from "gatsby-plugin-image";
import { IComponent } from "../../helpers/interfaces";
import { useEffect, useState } from "react";
import { findByKey } from '../../helpers/helpers';

const BasicMenu = ({ propertyReference }: IComponent): JSX.Element => {

  useEffect(() => {
    setCurrentLanguage();
  }, []);

  const [language, setLanguage] = useState('pl');
  const buttonSignUp = propertyReference.find(el => el.key === "show-button");
  const languages = findByKey(propertyReference, 'show-languages');
  const setCurrentLanguage = () => {
    setLanguage(window.location.pathname.split('/')[1]);
  };

  return (
    <header className="basic-menu-section">
      <div className="content-limiter basic-menu-container">
        <div className="logo">
          <a href="/">
            <StaticImage src={ "../../assets/images/logo.png" } layout={ "fixed" } width={ 170 } height={ 45 } alt="PushPushGo"/>
          </a>
        </div>
        <div className="basic-menu-buttons">
          { buttonSignUp && <a target="_blank" rel="noreferrer" href="https://app.pushpushgo.com/register" className="basic-menu-btn">{ buttonSignUp && buttonSignUp.value }</a> }
          { languages && <div className="language-toggle">
            <div className="language-toggle-list">
              <a href={ languages.value3 } className={ `${ language === "pl" ? `active` : '' }` } onClick={ () => setLanguage("pl") }>pl</a>
              <a href={ languages.value2 } className={ `${ language === "en" ? `active` : '' }` } onClick={ () => setLanguage("en") }>en</a>
            </div>
          </div> }
        </div>
      </div>
    </header>
  );
};

export default BasicMenu;
