import React from "react"
import "./WideFooter.scss"
import Icon from "../Icon/Icon"
import { StaticImage } from "gatsby-plugin-image"

const WideFooter = (props) => {

  const icons = props.propertyReference

  return (
    <footer className="wide-footer">
      <div className="logo">
        <a href="/">
          <StaticImage src={"../../assets/images/logo.png"} layout={"fixed"} width={170} height={45} alt="PushPushGo"/>
        </a>
      </div>
      <div className="icon-container">
        {icons.map((icon) => <Icon icon={icon} key={Math.random() + new Date().toDateString()}/>)}
      </div>
    </footer>
  )
}

export default WideFooter
