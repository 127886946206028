import React from "react";
import "./SingleInformation.scss";
import Markdown from 'react-markdown';

const SingleInformation = (props) => (
  <div className="single-info">
    <span className="span-icon">{props.value}</span>
    <Markdown children={props.content} />
  </div>
);

export default SingleInformation;
