import React from "react";
import "./TeamMembers.scss";
import TeamMember from "../TeamMember/TeamMember";
import Subtitle from "../Subtitle/Subtitle";

const TeamMembers = (props) => {

  const team = props.employees;
  const subtitleData = props.propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const noTopSpace = subtitleData && subtitleData.value2;

  return (
    <>
      <Subtitle text={subtitle} color={noTopSpace}/>
      <div className="team-members">
        {team.map((member) => <TeamMember key={Math.random() + new Date().toDateString()} {...member} />)}
      </div>
    </>
  );
};

export default TeamMembers;
