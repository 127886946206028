import React from "react";
import "./Testimonials.scss";
import Carousel from "../Carousel/Carousel"
import Subtitle from "../Subtitle/Subtitle"


const Testimonials = ({ propertyReference }) => {

  const testimonials = propertyReference.filter(testimonial => testimonial.key === "testimonial");

  const multipleSlides = propertyReference.some(el => el.key === "carousel-type");
  const mulipleClassData = propertyReference.find(el => el.key === "carousel-type");
  const mulipleClassName = multipleSlides ? mulipleClassData.value : undefined;
  const subtitleData = propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const color = subtitleData && subtitleData.value2;

  return (
  <section className="grey-bg testimonials">
    {subtitleData && <Subtitle text={subtitle} color={color}/>}
    <div className={`content-limiter testimonials-container ${mulipleClassName}`}>
       <Carousel testimonials={testimonials} multipleSlidesVisible={multipleSlides} />
    </div>
  </section>
)};

export default Testimonials;
