import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as Markdown from 'react-markdown';
import "./BenefitsPartner.scss";

interface IBenefitsPartnerProps {
  propertyReference: IPropertyReference[];
}

const BenefitsPartner: React.FC<IBenefitsPartnerProps> = ({ propertyReference }) => {
  const logoPartner = findByKey(propertyReference, "benefits-partner-logo");
  const description = findByKey(propertyReference, "benefits-partner-description").value;
  const benefitProfit = findByKey(propertyReference, "benefits-partner-profit");
  const linkToPartner = findByKey(propertyReference, "benefits-partner-link");
  const benefitConditions = findByKey(propertyReference, "benefits-partner-conditions");
  const redirectBtn = findByKey(propertyReference, "benefits-partner-btn-redirect");
  const backButton = findByKey(propertyReference, "benefits-partner-back-btn");

  return <section className="benefit-partner">
    <div className="benefit-partner__box">
      { logoPartner && <div className="benefit-partner__logo">
        <GatsbyImage alt={ logoPartner.image.alt || "" } image={ getImage(logoPartner.image.gatsbyImageData) }/>
      </div> }
      { description && <div className="benefit-partner__description"><Markdown children={ description }/></div> }
    </div>

    <div className="benefit-partner__box">
      <ul className="benefit-partner__infos">
        { benefitProfit && <li className="benefit-partner__info">
          <p className="benefit-partner__info-name">{ benefitProfit.value }</p>
          <Markdown className="benefit-partner__info-value" children={ benefitProfit.content }/>
        </li> }

        { linkToPartner && <li className="benefit-partner__info">
          <p className="benefit-partner__info-name">{ linkToPartner.value }</p>
          <a target="_blank" rel="noopener noreferrer" href={ linkToPartner.content } className="benefit-partner__info-value">{ linkToPartner.content }</a>
        </li> }

        { benefitConditions && <li className="benefit-partner__info">
          <p className="benefit-partner__info-name">{ benefitConditions.value }</p>
          <Markdown className="benefit-partner__info-value" children={ benefitConditions.content }/>
        </li> }
      </ul>

      { redirectBtn && <a target="_blank" rel="noopener noreferrer" href={ redirectBtn.content } className="benefit-partner__redirect-btn btn">{ redirectBtn.value }</a> }
      { backButton && <a href={ backButton.content } className="benefit-partner__back-btn">{ backButton.value }</a> }
    </div>
  </section>;
};

export default BenefitsPartner;
