import * as React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel';

import { IPropertyReference } from '../../helpers/interfaces';

import 'pure-react-carousel/dist/react-carousel.es.css';
import "./CarouselImage.scss";

interface ICarouselImageProps {
  carouselTitle: string;
  carouselItemsScenario: IPropertyReference[];
}

const CarouselImage: React.FC<ICarouselImageProps> = ({ carouselTitle, carouselItemsScenario }) => {

  return (
    <div className="carousel-image">
      <h3 className="carousel-image__title">{ carouselTitle }</h3>

      <CarouselProvider
        naturalSlideHeight={ 510 }
        naturalSlideWidth={ 510 }
        totalSlides={ carouselItemsScenario.length }
        visibleSlides={ 1 }
        isPlaying
      >

        <div className="carousel-image__box">

          <ButtonBack className="carousel-image__arrow" >
            <span className="icon-arrow-left"/>
          </ButtonBack>

          <Slider className="carousel-image__slider">
            { carouselItemsScenario.map((item: IPropertyReference, id: number): JSX.Element => {
              return <Slide index={ id } key={ Math.random() + Date.now() } className="carousel-image__slide">
                <GatsbyImage alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) } className="carousel-image__slide-image"/>
              </Slide>
            }) }
          </Slider>

          <ButtonNext className="carousel-image__arrow">
            <span className="icon-arrow-right"/>
          </ButtonNext>

        </div>

        <div className="carousel-image__dots">
          { carouselItemsScenario.map((item: IPropertyReference, id: number): JSX.Element => {
            return <Dot key={ id } slide={ id } className="carousel-image__dot"/>
          }) }
        </div>

      </CarouselProvider>
    </div>
  )
}

export default CarouselImage;
