import React from "react";
import "./TextBox.scss";

const TextBox = (props) => (
<div className="content-box">
  <h2>{props.title}</h2>
  <p>{props.content}</p>
</div>
);

export default TextBox;
