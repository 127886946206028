import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./CenteredIntroSection.scss";
import Icon from "../Icon/Icon";

const CenteredIntroSection = (props) => {

  const icons = props.propertyReference.filter(el => el.content === "icon");
  const title = props.propertyReference.find(el => el.key === "title");
  const subtitle = props.propertyReference.find(el => el.key === "subtitle");
  const btnContent = props.propertyReference.find(el => el.key === "btn-content");

  return (
    <section className="centered-intro-page">
      <div className="intro-content">
        <div className="image-container">
          <div className="animate-area">
            <div className="laptop-image">
              <StaticImage src={'../../assets/images/laptop.png'} layout={'constrained'} width={415} alt={'Image of laptop'} />
              <div className="push-fake push-desktop">
                <div className="push-icon">
                  <span className="icon-bell" />
                </div>
              </div>
            </div>
            <div className="iphone-image">
              <StaticImage src={'../../assets/images/iphone.png'} width={100} layout={'constrained'} alt={'Image of mobile phone'}/>
              <div className="push-fake push-mobile">
                <div className="push-icon">
                  <span className="icon-bell" />
                </div>
              </div>
            </div>
          </div>
            <div className="text-box">
              <h1>{title && title.value}</h1>
              <p>{subtitle && subtitle.value}</p>
              <a target="_blank" rel="noreferrer" href="https://app.pushpushgo.com/register" className="submit-button btn">{btnContent && btnContent.value}</a>
            </div>
        </div>
        <div className="icons-container">
          {icons.map((icon, idx) => <Icon icon={icon} key={idx}/>)}
        </div>
      </div>
    </section>
)};

export default CenteredIntroSection;
