import * as React from "react"
import "./Pricing.scss"
import ClickToCall from "./../ClickToCall/ClickToCall"
import useModal from "../useModal"
import { PlanCardTypes, PricingPlanCard } from "../PricingPlanCard/PricingPlanCard"

const Pricing = (props) => {
  const {isShowing, toggle} = useModal();
  const benefitsList = props.propertyReference[0].value.split(',')
  const plans = props.propertyReference.filter(property => property.key.indexOf('plan') > -1)
  const enterPricePlan = plans[0];

  return <>
    <section className="pricing-section">
      <div className="pricing-container">
        <div className="plans-container">
          <PricingPlanCard plan={enterPricePlan} toggleModal={toggle} labels={benefitsList} type={PlanCardTypes.ENTER_PRICE} />
        </div>
      </div>
      <ClickToCall c2c={props.c2c} lang={props.lang} isShowing={isShowing} hide={toggle} />
    </section>
  </>
};

export default Pricing;
