import React from "react";
import "./TextAndMediumIconsContainer.scss";
import MediumIcon from "../MediumIcon/MediumIcon";
import Markdown from 'react-markdown';
import classNames from "classnames";

const TextAndMediumIconsContainer = ({ propertyReference }) => {

  const mediumIcons = propertyReference.filter(el => el.key === "icon");
  const text = propertyReference.find(el => el.key === "text").content;
  const greenVersion = propertyReference.find(el => el.value === "green-version");
  const greyVersion = propertyReference.find(el => el.value === "grey-version");

  const classValue = classNames({
    "main": true,
    "text-and-medium-icons-container": true,
    "grey-version": greyVersion && greyVersion.value,
    "green-version": greenVersion && greenVersion.value,
  })

  return (
 <section className={classValue}>
   <div className="content-limiter wrapper">
     <div className="text-container">
       <Markdown children={text} />
     </div>
     <div className="medium-icon-container">
       {mediumIcons.map((mediumIcon) => <MediumIcon {...mediumIcon} key={Math.random() + new Date().toDateString()}/>)}
     </div>
   </div>
 </section>
)};

export default TextAndMediumIconsContainer;
