import React from "react";
import "./ContactCardForm.scss";

const ContactCardForm = (props) => {
  return <div className="contact-card-form-container">
      <div className="contact-card-form-avatar">
        <img src={props.card.image.url} alt=""/>
      </div>
      <div className="contact-card-content">
        <p className="contact-card-info">
          {props.card.content}
        </p>
        <div className="contact-card-details">
          <span className="contact-card-details-name">{props.card.value}</span>
          <p className="contact-card-details-description">{props.card.value2}</p>
          <p className="contact-card-details-email"><a href={`mailto:${props.card.value3}`}>{props.card.value3}</a> </p>
        </div>
      </div>
    </div>
};

export default ContactCardForm;
