import React from "react";
import "./JobOffer.scss";
import { parseHTML } from "../../helpers/parseHTML"
import { GatsbyImage } from "gatsby-plugin-image";

const JobOffer = (props) => {

  const { sneakPeak, backgroundImage, content, isintrovertical } = props.offerDetails;

  const { lang } = props;
  let btnContent;
  let url;

  switch (lang) {
    case "pl":
      btnContent = "< Powrót do ofert pracy";
      url = "/pl/pages/praca/";
      break;
    case "es":
      btnContent = "< Volver a ofertas de trabajo";
      url = "/es/pages/carrera/";
      break;
    case "en":
      btnContent = "< Return to job offers";
      url = "/en/pages/career/";
      break;
    default:
      btnContent = "< Return to job offers";
      url = "/en/pages/career/";
  }


  return (
    <section className="job-offer-section">
      <div className="content-limiter job-offer-container">
            <div>{parseHTML(sneakPeak)}</div>
        {content && <div>{parseHTML(content)}</div>}
      </div>
    </section>
  );
};

export default JobOffer;
