import React from "react";
import "./CaseStudyCard.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const CaseStudyCard = (props) => {

  const { article, lang } = props;
  let btnContent;

  switch (lang) {
    case "pl":
      btnContent = "Czytaj dalej >";
      break;
    case "en":
      btnContent = "Read more >";
      break;
    case "es":
      btnContent = "Lee más >";
      break;
    default:
      btnContent = "Read more >";
  }

  const { backgroundImage, title, sneakPeak, url } = article;

  return (
    <div className="case-study-card">
      <a href={url}>
        <GatsbyImage className="case-study-article-image"
                     alt={backgroundImage.alt || ""}
                     image={backgroundImage.gatsbyImageData}/>
      </a>
      <div className="case-study-article-text">
        <a className="case-study-article-title" href={url}>{title}</a>
        {sneakPeak && <p className="case-study-article-description">{sneakPeak}</p>}
        <div className="btn case-study-card-button-wrapper">
          <a href={url} className="btn case-study-card-button">{btnContent}</a>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyCard;
