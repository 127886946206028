import * as React from "react";
import { useState } from "react";
import CarouselImage from "../CarouselImage/CarouselImage";

import { IPropertyReference } from '../../helpers/interfaces';

import {findByKey, filterByKey} from "../../helpers/helpers";

import "./CarouselImageWrapper.scss";

interface ICarouselImageWrapperProps {
  propertyReference: IPropertyReference[];
}

const CarouselImageWrapper: React.FC<ICarouselImageWrapperProps> = ({ propertyReference }) => {

  const [visibleScenario, setVisibleScenario] = useState<number>(1);

  const contentTitle = findByKey(propertyReference, "content-title").value;
  const contentDescription = findByKey(propertyReference, "content-description").value;
  const contentSubtitle = findByKey(propertyReference, "content-subtitle").value;
  const carouselTitle = findByKey(propertyReference, "carousel-title").value;

  const carouselItemsTitle = filterByKey(propertyReference, "carousel-items-title");
  const carouselItemsScenario = filterByKey(propertyReference, `carousel-items-${ visibleScenario }`);

  const handleChooseScenario = (id: number): void => {
    setVisibleScenario(id + 1)
  }

  const automationScenarios = carouselItemsTitle.map((item: IPropertyReference, id: number): JSX.Element => {
    return <li key={ id } onClick={ () => handleChooseScenario(id) }
               className={ id === visibleScenario - 1 ? "automation-scenarios__item automation-scenarios__item--active" : "automation-scenarios__item" }>{ item.value }</li>
  })

  return (
    <section className="carousel-image-wrapper">

      <div className="carousel-image-content">
        <h2 className="carousel-image-content__title">{ contentTitle }</h2>
        <p className="carousel-image-content__description">{ contentDescription }</p>

        <p className="carousel-image-content__subtitle">{ contentSubtitle }</p>

        <div className="automation-scenarios">
          <ul className="automation-scenarios__list">
            { automationScenarios }
          </ul>
        </div>
      </div>

      <CarouselImage key={ Math.random() + Date.now() } carouselTitle={ carouselTitle } carouselItemsScenario={ carouselItemsScenario }/>

    </section>
  )
}

export default CarouselImageWrapper;
