import React from "react";
import "./Testimonial.scss";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

const Testimonial = (props) => (
  <div className="testimonial-item">
    <div className="testimonial-quote">
      <Markdown className="title" children={props.value}/>
      <Markdown children={props.content}/>
      <div className="testimonial-footer">
        <GatsbyImage alt={props.image.alt || ""} image={props.image.gatsbyImageData} className="testimonial-icon"/>
        <div className="testimonial-autor">
          {props.title2}
          <span className="autor-position">{props.value2}
            <a href={props.value3} rel="noreferrer" target="_blank"> {props.title3}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default Testimonial;
