import React from "react";
import "./NumberStats.scss";
import SingleChart from "../SingleChart/SingleChart";
import Subtitle from "../Subtitle/Subtitle"

const NumberStats = ({ propertyReference }) => {

  const charts = propertyReference.filter(el => el.key !== 'subtitle');
  const subtitleData = propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const icon = subtitleData && subtitleData.value2;
  const noSpace = subtitleData && subtitleData.value3;

return (
  <>
    {subtitleData && <Subtitle text={subtitle} icon={icon} color={noSpace}/>}
    <section className="main">
      <div className="content-limiter">
        <div className="number-stats">
          {charts.map((chart, idx) => <SingleChart {...chart} key={idx}/>)}
        </div>
      </div>
    </section>
  </>
)};

export default NumberStats;
