import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./BenefitsTitleSection.scss";

interface IBenefitsTitleSectionProps {
  propertyReference: IPropertyReference[];
}

const BenefitsTitleSection: React.FC<IBenefitsTitleSectionProps> = ({ propertyReference }) => {

  const title = findByKey(propertyReference, "benefits-title").value;
  const description = findByKey(propertyReference, "benefits-description").value;
  const image = findByKey(propertyReference, "benefits-image");

  return <section className="benefits-title">
    <div className="benefits-title__content-box">
      { title && <h1 className="benefits-title__title">{ title }</h1>}
      {description && <p className="benefits-title__description">{ description }</p>}
    </div>

    { image.image && <div className="benefits-title__image-box">
      <GatsbyImage alt={ image.image.alt || "" } image={ getImage(image.image.gatsbyImageData) } className="benefits-title__image"/>
    </div>}
  </section>;
};

export default BenefitsTitleSection;
