import React from "react";

const ReadTime = (props) => {

  const content = props.content || '';
  const matches = content.replace(/(<([^>]+)>)/ig, '').match(/[\u0400-\u04FF]+|\S+\s*/g);
  const count = matches !== null ? matches.length : 0;
  const wordPerMin = 300;
  const readTime = Math.ceil(count/wordPerMin);

return (
  <span>{readTime} min</span>
)};

export default ReadTime;
