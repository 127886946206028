import React from "react";
import "./DemoRequest.scss";
import Markdown from 'react-markdown';
import BasicForm from "../BasicForm/BasicForm";

const DemoRequest = ({ propertyReference }) => {

  const colorVersion = propertyReference.find(el => el.key === "color");
  const content = propertyReference.find(el => el.key === "text").value;
  const btnContent = propertyReference.find(el => el.key === "btn-content");

  return (
  <div className={`demo-request ${colorVersion && colorVersion.value}`}>
    <div className="demo-box">
      <Markdown children={content} />
      <BasicForm {...btnContent}/>
    </div>
  </div>
)};

export default DemoRequest;
