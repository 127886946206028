import * as React from "react"
import "./ContactForm.scss"
import {
  Form,
  Field,
  Formik,
  ErrorMessage
} from 'formik';
import { useState } from "react"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { regexpEmail } from "../../helpers/regexp";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


const ContactForm = ({ form, validators, placeholders }) => {
  const [isFormSubmited, setFormSubmit] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState('')

  return (
    <div className="contact-form">
      <span className="contact-form-title">{form.value}</span>
      <Formik
        initialValues={{
          name: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          companyName: '',
          companyWebsite: '',
        }}
        onSubmit={
          (values, actions) => {
            values.phoneNumber = phoneNumberValue;
            fetch("/en", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "contact-form-data", ...values })
            })
              .then((res) => {
                setFormSubmit(true);
                setPhoneNumberValue('');
                actions.resetForm()
              })
              .catch((er) => {
                setFormSubmit(false);
                console.log(er)
              })
              .finally(() => actions.setSubmitting(false))
          }
        }
        validate={values => {
          const errors = {};

          if(!values.name) {
            errors.name = validators.value2
          }
          if(!values.lastName) {
            errors.lastName = validators.value3
          }
          if(!values.email || !regexpEmail.test(values.email)) {
            errors.email = validators.value5
          }
          if(!values.companyName) {
            errors.companyName = validators.value6
          }
          return errors;
        }}
      >
        {() => (
          <Form name="contact-form-data" data-netlify={true}>
            <label htmlFor="name">{form.value2} </label>
            <Field name="name" className="input-field" placeholder={placeholders.value2} />
            <span className="error-message"><ErrorMessage name="name" /></span>

            <label htmlFor="lastName">{form.value3} </label>
            <Field name="lastName" className="input-field" placeholder={placeholders.value3}/>
            <span className="error-message"><ErrorMessage name="lastName"/></span>

            <label htmlFor="phoneNumber">{form.value4} </label>
            <PhoneInput
              name="phoneNumber"
              placeholder="Enter phone number"
              value={phoneNumberValue}
              onChange={setPhoneNumberValue}
              className="custom-phone-input"
            />

            <label htmlFor="email">{form.value5} </label>
            <Field name="email" className="input-field" placeholder={placeholders.value5} />
            <span className="error-message"><ErrorMessage name="email"/></span>

            <label htmlFor="companyName">{form.value6} </label>
            <Field name="companyName" className="input-field" placeholder={placeholders.value6}/>
            <span className="error-message"><ErrorMessage name="companyName"/></span>

            <label htmlFor="companyWebsite">{form.value7} </label>
            <Field name="companyWebsite" className="input-field" placeholder={placeholders.value7}/>
            {isFormSubmited && <span className="submit-message">{validators.value8}</span>}

            <button type="submit">{placeholders.value8}</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
