import * as React from "react";
import PartnershipStep from "../PartnershipStep/PartnershipStep";
import Subtitle from "../Subtitle/Subtitle";
import './BecomingPartnerSection.scss';
import { IComponent } from "../../helpers/interfaces";

const BecomingPartnerSection = ({propertyReference}: IComponent): JSX.Element => {

  const title = propertyReference.find(el => el.key === "title");
  const steps = propertyReference.filter(el => el.key === "partnershipStep");

  return <section className="becoming-partner-steps content-limiter">
    <Subtitle text={title.value} fontSize="s"/>
    <div className="steps-container">
      {steps.map((step, idx) => {
        return <PartnershipStep key={idx} stepNumber={step.value} img={step.image.url} reverse={step.title2 === "reverse"} imgSize={step.value3}>
          {step.content}
        </PartnershipStep>
      })}
    </div>
  </section>
}
export default BecomingPartnerSection;
