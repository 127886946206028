import React from "react";
import "./Carousel.scss";
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Testimonial from "../Testimonial/Testimonial";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesVisible: 1,
    }
    this.setSlidesVisible = this.setSlidesVisible.bind(this);
  }

  componentDidMount() {
    if (this.props.multipleSlidesVisible) {
      this.setSlidesVisible();
    }
      window.addEventListener("resize", this.setSlidesVisible);
  }

  shouldComponentUpdate(nextProps, nextState) {
      return this.state.slidesVisible !== nextState.slidesVisible;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSlidesVisible);
  }


  setSlidesVisible() {
    if (this.props.multipleSlidesVisible) {
      const deviceWidth = window.innerWidth;
      let slidesVisible = deviceWidth <= 900 ? 1 : deviceWidth <=1300 ? 2 : 3;
      this.setState({ slidesVisible });
    }
  }

  render() {
    const { testimonials } = this.props;

    return (
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={110}
        totalSlides={testimonials.length}
        visibleSlides={this.state.slidesVisible}>
        <div className="flex-wrapper">
          <ButtonBack className="arrow">
            <span className="icon-arrow-left" />
          </ButtonBack>
          <Slider className="slider-container">
            {testimonials.map((testimonial, idx) => {
              return <Slide index={idx} key={ Math.random() + new Date().toDateString() }><Testimonial {...testimonial}/></Slide>
            })}
          </Slider>
          <ButtonNext className="arrow">
            <span className="icon-arrow-right" />
          </ButtonNext>
        </div>
      </CarouselProvider>
    );
  }
}

export default Carousel;
