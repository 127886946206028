import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as Markdown from "react-markdown";
import "./JobOffersIntro.scss";

interface IJobOffersIntroProps {
  propertyReference: IPropertyReference[];
}

const JobOffersIntro: React.FC<IJobOffersIntroProps> = ({ propertyReference }) => {

  const info1 = findByKey(propertyReference, "job-offers-info-1").value;
  const info2 = findByKey(propertyReference, "job-offers-info-2").value;
  const info3 = findByKey(propertyReference, "job-offers-info-3").value;
  const info4 = findByKey(propertyReference, "job-offers-info-4").value;
  const image = findByKey(propertyReference, "job-offers-image");

  return <section className={info1 ? "job-offers-intro" : "job-offers-intro--language"}>
    {info1 && <Markdown children={ info1 }/>}
    {image.image && <div className="job-offers-intro__image">
      <GatsbyImage alt={ image.image.alt || "" } image={ getImage(image.image.gatsbyImageData) }/>
    </div>}
    {info2 && <p>{ info2 }</p>}
    {info3 && <p>{ info3 } <a href="mailto:jobs@pushpushgo.com">jobs@pushpushgo.com</a>. { info4 }</p>}
  </section>;
};

export default JobOffersIntro;
