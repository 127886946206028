import React from "react";
import "./ourTools.scss";
import Tool from "../Tool/Tool";
import Subtitle from "../Subtitle/Subtitle";

const OurTools = (props) => {

  const tools = props.propertyReference.filter(el => el.key !== 'subtitle');
  const subtitleData = props.propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const color = subtitleData && subtitleData.value2;
  const icon = subtitleData && subtitleData.value3;
  return (
  <>
    <Subtitle text={subtitle} color={color} icon={icon}/>
    <section className="our-tools-section features grey-bg">
      <div className="content-limiter">
         <ul className="features-list in-viewport">
           {tools.map(tool => <Tool  {...tool} key={Math.random() + new Date().toDateString()} />)}
         </ul>
      </div>
    </section>
    </>
)};

export default OurTools;
