import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./EBookAuthors.scss";

interface IEBookAuthorsProps {
  propertyReference: IPropertyReference[];
}

const EBookAuthors: React.FC<IEBookAuthorsProps> = ({ propertyReference }) => {

  const authorsTitle = findByKey(propertyReference, "ebook-authors-title").value;
  const authorsSubtitle = findByKey(propertyReference, "ebook-authors-subtitle").value;
  const authorsItemsList = filterByKey(propertyReference, "ebook-authors-items");

  const authorsItems = authorsItemsList.map((item: IPropertyReference, id: number): JSX.Element => {
    return item.image && item.value && item.content && item.title2 && <div key={ id } className="author">
      <GatsbyImage alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) } className="author__image"/>
      <p className="author__name">{ item.value }</p>
      <p className="author__job-position">{ item.content }</p>
      <p className="author__email">{ item.title2 }</p>
    </div>;
  });

  return <section className="ebook-authors">
    { authorsTitle && <h3 className="ebook-authors__title">{ authorsTitle }</h3> }
    { authorsSubtitle && <h4 className="ebook-authors__subtitle">{ authorsSubtitle }</h4> }
    { authorsItems && <div className="authors">{ authorsItems }</div> }
  </section>;
};

export default EBookAuthors;
