import * as React from "react";
import { parseHTML } from "../../helpers/parseHTML"
import "./SingleStep.scss";

interface ISingleStepProps {
  step: string;
  idx: number;
}

const SingleStep = ({step, idx}: ISingleStepProps) => (
  <div className="video-section__step">
    <span className="video-section__step-number">{idx + 1}</span>
    <p>{parseHTML(step)}</p>
  </div>
);

export default SingleStep;
