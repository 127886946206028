import * as React from 'react';
import './Step.scss'
import { FC } from "react"
import { IPropertyReference } from "../../helpers/interfaces"

interface IStepProps {
  number: number;
  property: IPropertyReference;
  isLastItem: boolean
}

const Step:FC<IStepProps> = ({number, property, isLastItem }) => {

  const isDatoImageLink = (value:string): boolean => {
    return value.includes('datocms-assets')
  }

  const getDescriptionValues = () => {
    const values = Object.entries(property).filter(item => {
      if(item[0].includes('value')) {
        return item[1];
      }
    })
    values.shift();

    return values;
  }

  return <div className={`step-wrapper ${isLastItem && 'last-item'}`}>
    <div className={'step-header'}>
      <div className={'step-header_number'}>{ number }</div>
      <span className={"step-header_title"}>{ property.content }</span>
    </div>

    <div className={`step-body`}>
      { <div className={'step-body_description'}>{ property.value }</div> }
      { property.image && <img src={property.image.url} /> }
      { getDescriptionValues().map((record) => {
        const value = record[1]

        if(isDatoImageLink(value)){
          return <img className='step-body_img' src={value.trim()}/>
        }
          return <p>{value}</p>
      })}

    </div>
  </div>
}

export default Step;
