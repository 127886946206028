import React, { useEffect } from "react";
import "./TestPush.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const noop = () => {
};

const TestPush = (props) => {

  useEffect(() => {

    document.querySelectorAll(".test-push-content button").forEach(function(node) {
      if (!node) {
        return;
      }

      const ga = window["ga"] || noop;

      node.addEventListener("click", function() {
        const title = node.innerHTML;
        ga("send", "event", "outbound", "click", title, {
          "transport": "beacon",
        });
      });
    });
  }, []);


  const btn = props.propertyReference.find(el => el.title2 === "btn-content");
  const btnContent = btn && btn.value2;
  const title = props.propertyReference.find(el => el.key === "title");
  const titleContent = title && title.value;
  const paragraph = title && title.content;
  const image = title.image;
  const pushTitle = title && title.value3;
  const pushBody = title && title.value4;

  async function send(title, url, options) {
    if (!window.Notification) {
      return alert("Browser does not support system notifications");

    }

    const isGranted = await Notification.requestPermission();
    if (isGranted === "denied") {
      return;

    }

    const notification = new Notification(title, options);
    notification.addEventListener("click", () => {
      window.open(url, "_blank");
    });

  }

  const sendTestPush = () => {
    send(`${pushTitle}`, "https://pushpushgo.com/pl/", { body: `${pushBody}`, icon: "https://bit.ly/2Jb3ZXf" });
  };
  return (
    <section className="test-push-section">
      <div className="content-limiter test-push-container">
        <div className="test-push-content">
          <h1>{titleContent}</h1>
          <p>{paragraph}</p>
          <button onClick={sendTestPush}>{btnContent}</button>
        </div>
        {image && <GatsbyImage className="test-push-image"
                               alt={image.alt || ""}
                               image={image.gatsbyImageData}/>}
      </div>
    </section>
  );
};

export default TestPush;
