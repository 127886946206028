import * as React from "react";
import * as Markdown from "react-markdown";
import { findByKey, filterByKey } from "../../helpers/helpers";
import { IPropertyReference } from '../../helpers/interfaces';
import YouTubePlayer from "../YouTubePlayer/YoutubePlayer"
import SingleStep from "../SingleStep/SingleStep";
import "./VideoComponent.scss";

interface IVideoComponentProps {
  propertyReference: IPropertyReference[];
}

const VideoComponent: React.FC<IVideoComponentProps> = ({ propertyReference }) => {

  const title = findByKey(propertyReference, "title");
  const paragraph = findByKey(propertyReference, "paragraph");
  const button = findByKey(propertyReference, "btn-content");
  const video = findByKey(propertyReference, "url").value;
  const steps = filterByKey(propertyReference, "video-component-steps");

  return <section className="video-section">
    <div className="content-limiter video-section__wrapper">
      <div className="video-section__text-box">
        <h2 className="video-section__title">{ title.value }</h2>
        <Markdown children={ paragraph.value }/>
        <Markdown children={ paragraph.value2 }/>
        <a href={ button.content } className="btn video-section__button">{ button.value }</a>
      </div>
      <YouTubePlayer className="video-section__video" url={ video } width="800px" height="450px" controls/>
      <div className="video-section__steps">
        { steps && steps.map((step, idx) => {
          return <SingleStep key={ Math.random() + new Date().toDateString() } step={ step.value } idx={ idx }/>;
        }) }
      </div>
    </div>
  </section>;
};

export default VideoComponent;
