import React from "react";
import "./PrivacySection.scss";
import PrivacySingleInfo from "../PrivacySingleInfo/PrivacySingleInfo";
import Subtitle from "../Subtitle/Subtitle";

const PrivacySection = (props) => {

  const subtitleData = props.propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const noSpaces = subtitleData && subtitleData.value2;
  const icon = subtitleData && subtitleData.value3;
  const questions = props.propertyReference.filter(el => el.key !== 'subtitle');

return(
  <>
    {subtitleData && <Subtitle text={subtitle} color={noSpaces} icon={icon}/>}
    <section className="privacy-section">
      <div className="content-limiter">
        <div className="privacy-container">
          {questions.map((singleInfo) => <PrivacySingleInfo {...singleInfo} key={Math.random() + new Date().toDateString()} />)}
        </div>
      </div>
    </section>
  </>
)};

export default PrivacySection;
