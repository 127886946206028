import React from "react";
import "./RODOQuestions.scss";
import Subtitle from "../Subtitle/Subtitle";
import Markdown from "react-markdown";

const RODOQuestions = (props) => {

  const subtitleData = props.propertyReference.find(el => el.key === "subtitle");
  const subtitle = subtitleData && subtitleData.value;
  const noSpaces = subtitleData && subtitleData.value2;
  const questions = props.propertyReference.filter(el => el.key !== 'subtitle');

return(
  <>
    {subtitleData && <Subtitle text={subtitle} color={noSpaces}/>}
    <section className="rodo-questions-section">
      <div className="content-limiter rodo-questions-container">
        {questions.map((question) => {
          return <div className="rodo-question" key={Math.random() + new Date().toDateString()}>
                    <p className="question">{question.value}</p>
                    <Markdown children={question.content} />
                </div>
        })}
      </div>
    </section>
  </>
)};

export default RODOQuestions;
