import React from "react";
import "./SingleBenefit.scss";

const SingleBenefit = (benefit) => (
  <li className="single-benefit">
      <span className={`list-icon ${benefit.value}`} />
      <span className="list-content">{benefit.content}</span>
      <span className="list-tick">
          <span className="icon-ok-icon text-green" />
      </span>
  </li>
);

export default SingleBenefit;
