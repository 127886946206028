import * as React from "react";
import "./Subtitle.scss";

interface ISubtitle {
  text: string;
  fontColor?: string;
  color?: string;
  icon?: string;
  fontSize?: string;
}

const Subtitle = ({fontColor, color, icon, text, fontSize}: ISubtitle): JSX.Element => (
  <header className={`subtitle-header ${color}`}>
    {icon && <span className={`icon ${icon}`} />}
    <h2 className={`subtitle-title ${fontColor} ${fontSize}`}>{text}</h2>
  </header>
);

export default Subtitle;
