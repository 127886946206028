import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./EBookTitleSection.scss";

interface IEBookTitleSectionProps {
  propertyReference: IPropertyReference[];
}

const EBookTitleSection: React.FC<IEBookTitleSectionProps> = ({ propertyReference }) => {

  const title = findByKey(propertyReference, "ebook-title");
  const description = findByKey(propertyReference, "ebook-description");
  const subtitleTop = findByKey(propertyReference, "ebook-subtitle-top").value;
  const subtitleBottom = findByKey(propertyReference, "ebook-subtitle-bottom");
  const imageIcons = findByKey(propertyReference, "ebook-image-icons");
  const image = findByKey(propertyReference, "ebook-image");
  const bgColor = findByKey(propertyReference, "ebook-title-background").value;

  return <section className={ `ebook-title ${ bgColor }` }>
    <div className="ebook-title__wrapper">
      <div className="ebook-title__box">
        { subtitleTop && <p className="ebook-title__subtitle-top">{ subtitleTop }</p> }
        { title && <h1 className="ebook-title__title">{ title.value }
          { title.content && <span className="ebook-title__title--extra-info">{ title.content }</span> }
        </h1> }

        { description && <h2 className="ebook-title__description">
          { description.value }
          { description.content && <span className="ebook-title__description--span">{ description.content }</span> }
        </h2> }

        { subtitleBottom && <p className="ebook-title__subtitle-bottom">{ subtitleBottom.value }</p> }
      </div>

      <div className="ebook-title__box">
        { imageIcons && <div className="ebook-title__image-icons">
          <GatsbyImage alt={ imageIcons.image.alt || "" } image={ getImage(imageIcons.image.gatsbyImageData) }/>
        </div> }
        { image && <div className="ebook-title__image">
          <GatsbyImage alt={ image.image.alt || "" } image={ getImage(image.image.gatsbyImageData) }/>
        </div> }
      </div>
    </div>
  </section>;
};

export default EBookTitleSection;
