import React from "react";
import "./CompanyInfo.scss";

const CompanyInfo = (props) => {

  const companyData = props.propertyReference.find(el => el.key === 'company-name');

  return (
  <section className="company-info-section">
    <div className="content-limiter company-info-container">
      <address>
        <p className="company-name">{companyData.value}</p>
        <p>{companyData.value2}<br/>
          {companyData.value3} <br/>
          {companyData.value4}</p>
      </address>
      <div>
        <p>{companyData.value5}<br />
          {companyData.value6} <br />
          {companyData.value7}</p>
        <p>{companyData.value8}<br />
          {companyData.value9} <br />
          {companyData.value10}</p>
      </div>
    </div>
  </section>
)};

export default CompanyInfo;
