import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MapContainer = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 50.0957674, lng: 19.9624382 }}>
    {props.isMarkerShown && <Marker position={{ lat: 50.0957674, lng: 19.9624382 }} />}
  </GoogleMap>
));

export default MapContainer;
