import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as Markdown from "react-markdown";
import "./StrategyPushOpinions.scss";

interface IStrategyPushOpinionsProps {
  propertyReference: IPropertyReference[];
}

const StrategyPushOpinions: React.FC<IStrategyPushOpinionsProps> = ({ propertyReference }) => {
  const title = findByKey(propertyReference, "strategy-push-opinions-title").value;
  const opinionsList = filterByKey(propertyReference, "strategy-push-opinions");
  const clientsList = filterByKey(propertyReference, "strategy-push-opinions-clients");

  const opinions = opinionsList.map((opinion: IPropertyReference, id: number) => {
    return <li key={ id } className="strategy-opinions__opinion">
      <div className="strategy-opinions__author-image">
        <GatsbyImage alt={ opinion.image.alt || "" } image={ getImage(opinion.image.gatsbyImageData) }/>
      </div>
      <div className="strategy-opinions__opinion-info">
        <Markdown children={ opinion.content } className="strategy-opinions__description"/>
        <p className="strategy-opinions__author-name">{ opinion.value }</p>
        <p className="strategy-opinions__author-job-position">{ opinion.value2 }</p>
      </div>
    </li>;
  });

  const clients = clientsList.map((client: IPropertyReference, id: number) => {
    return <div key={ id } className="strategy-opinions__client-logo">
      <GatsbyImage alt={ client.image.alt || "" } image={ getImage(client.image.gatsbyImageData) }/>
    </div>;
  });

  return <section className="strategy-opinions">
    <h2 className="strategy-opinions__title">{ title }</h2>
    <ul className="strategy-opinions__opinions">{ opinions }</ul>
    <div className="strategy-opinions__clients">{ clients }</div>
  </section>;
};

export default StrategyPushOpinions;
