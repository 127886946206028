import React from "react";
import "./InfoBox.scss";
import SingleInformation from "../SingleInformation/SingleInformation";

const InfoBox = ({ propertyReference }) => {

  const singleInformation = propertyReference.filter(el => el.key !== 'space');
  const space = propertyReference.find(el => el.key === 'space');
  const spaceData = space && space.value;

  return (
  <section className={`main info-box-section ${spaceData}`}>
    <div className="content-limiter info-box">
      {singleInformation.map(info => <SingleInformation {...info} key={Math.random() + new Date().toDateString()} />)}
    </div>
  </section>
)
};

export default InfoBox;
