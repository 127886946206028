import * as React from "react";
import { useEffect, useState } from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, getLanguageByLocation } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import Markdown from "react-markdown";
import { regexpEmail } from '../../helpers/regexp';
import { useLocation } from '@reach/router';
import "./EBookInfo.scss";
import { EmailForm } from '../EmailForm/EmailForm';

interface IEBookInfoProps {
  propertyReference: IPropertyReference[];
}

const EBookInfo: React.FC<IEBookInfoProps> = ({ propertyReference }) => {
  const location = useLocation();

  const iconQuote = findByKey(propertyReference, "ebook-icon-quote");
  const paragraphs = findByKey(propertyReference, "ebook-paragraphs");
  const listItems = findByKey(propertyReference, "ebook-list-items");
  const formInput = findByKey(propertyReference, "ebook-form-input").value;
  const formBtnSubmit = findByKey(propertyReference, "ebook-form-btn-submit").value;
  const thankYouLink = findByKey(propertyReference, "ebook-form-link-thank-you").value;
  const ebookName = findByKey(propertyReference, "ebook-name").value;
  const infoSend = findByKey(propertyReference, "ebook-form-info-send");
  const bgColor = findByKey(propertyReference, "ebook-info-background").value;
  const groupName = findByKey(propertyReference, "ebook-contact-group-name").value;

  const permissionData = findByKey(propertyReference, "ebook-permission");
  const permissionInfo = permissionData && permissionData.content || '';
  const permissionLink = permissionData && permissionData.value2 || '';
  const permissionLinkDescription = permissionData && permissionData.value || '';

  const [email, setEmail] = useState<string>("");
  const [validateMsg, setValidateMsg] = useState<string>("");
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (isFormSubmitted) {
      navigate(`${ thankYouLink }`);
      setIsFormSubmitted(false);
      setEmail("");
    }
  }, [isFormSubmitted]);

  const validateEmail = (): RegExpMatchArray => {
    return email.match(regexpEmail);
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail()) {
      return setValidateMsg("Invalid e-mail address");
    }

    setValidateMsg("");

    const language = getLanguageByLocation(location);

    fetch("https://api.pushpushgo.com/notification/newsletter/ebook", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        ebookName,
        language,
        groupName
      })
    }).then((res) => {
      setIsFormSubmitted(true);
    }).catch(err => {
      console.log("Error: ", err);
    });
  };

  return <section className={ `ebook-info ${ bgColor }` }>
    { iconQuote && <div className="ebook-info__quote">
      <GatsbyImage alt={ iconQuote.image.alt || "" } image={ getImage(iconQuote.image.gatsbyImageData) }/>
    </div> }

    <div className="ebook-info__info">
      { paragraphs && <div className="ebook-info__paragraphs"><Markdown children={ paragraphs.value }/></div> }
      { listItems && <Markdown children={ listItems.value } className="ebook-info__list-items"/> }
    </div>

    <EmailForm setEmail={setEmail}
               infoSend={infoSend}
               validateMsg={validateMsg}
               handleSubmitForm={handleSubmitForm}
               formInput={formInput}
               permissionLink={ permissionLink }
               permissionInfo={ permissionInfo }
               permissionLinkDescription={ permissionLinkDescription }
               formBtnSubmit={formBtnSubmit}/>
  </section>;
};

export default EBookInfo;
