import React from "react"
import "./JobOffers.scss"

const JobOffers = (props) => {

  const { lang } = props

  const tableDetails = props.propertyReference.find(el => el.key === "role")
  let pageName;
  const content = {}

  switch (lang) {
    case "pl":
      content.content = "Napisz do nas na adres:";
      content.description = "i wyślij swoje CV lub po prostu opowiedz nam o sobie";
      pageName = 'praca'
      break
    case "es":
      content.content = "Escríbenos"
      content.description = "y envía tu CV o cuéntanos sobre ti";
      pageName = 'carrera'
      break
    case "en":
      content.content = "Write to us"
      content.description = "and send your CV or just tell us about yourself"
      pageName = 'career'
      break
    default:
      content.content = "Write to us"
      content.description = "and send your CV or just tell us about yourself"
      pageName = 'career'
  }

  return (
    <section className="job-offers-section">
      <div className="content-limiter job-offers-container">
        {props.jobs && props.jobs.length > 0 ?
          (<table>
              <thead>
              <tr>
                <th>{tableDetails.value}</th>
                <th>{tableDetails.value2}</th>
                <th>{tableDetails.value3}</th>
                <th>{tableDetails.value4}</th>
              </tr>
              </thead>
              <tbody>
              {props.jobs.map(offer => {
                return <tr key={Math.random() + new Date().toDateString()}>
                  <td><a href={`/${props.lang}/${pageName}/${offer.slug}`}>{offer.title}</a></td>
                  <td><span className="icon-location"/> {offer.location}</td>
                  <td>{offer.category.name}</td>
                  <td>{offer.endDate}</td>
                </tr>
              })}
              </tbody>
            </table>
          ) : <div className="text-center">{content.content} <a
            href="mailto:jobs@pushpushgo.com">jobs@pushpushgo.com</a><br/>
            {content.description} :)</div>
        }
      </div>
    </section>
  )
}

export default JobOffers
