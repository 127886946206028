import React from "react";
import "./SingleChart.scss";
import Markdown from 'react-markdown';

const SingleChart = (props) => (
  <div className="stat-item">
    <div className={`pie-wrapper ${props.content}`}>
      <span className="label">{props.value}<span className="smaller">%</span></span>
      <div className="pie">
        <div className="left-side half-circle" />
        <div className="right-side half-circle" />
      </div>
    </div>
    <div className="stat-name">
      <Markdown children={props.value2} />
      <Markdown children={props.value3} />
    </div>
  </div>
);

export default SingleChart;
