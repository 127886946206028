import React from "react";
import "./OurClients.scss";
import Subtitle from "../Subtitle/Subtitle";
import { GatsbyImage } from "gatsby-plugin-image";

const OurClients = ({ propertyReference }) => {

  const clients = propertyReference.filter(el => el.key !== "subtitle");
  const subtitleData = propertyReference.find(el => el.key === "subtitle");
  const subtitle = subtitleData && subtitleData.value;
  const noSpace = subtitleData && subtitleData.value2;

  return (
    <div className="our-clients">
      {subtitle && <Subtitle text={subtitle} color={noSpace}/>}
      <div className="our-clients-container content-limiter">
        {clients.map(client => {
          const imgType = client.value2 === "image" ? `imgType` : undefined;
          return client.value3 ? (
            <a href={client.value3} rel="noreferrer" key={Math.random() + new Date().toDateString()} target="_blank">
              <GatsbyImage alt={client.alt || ""} image={client.image.gatsbyImageData} className={`${imgType}`}/>
            </a>
          ) : (
            <GatsbyImage image={client.image.gatsbyImageData}
                         key={Math.random() + new Date().toDateString()}
                         alt={client.alt || ""}
                         className={`${imgType}`}/>
          );
        })}
      </div>
    </div>
  );
};

export default OurClients;

