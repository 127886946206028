import React from "react";
import "./ContactSection.scss";
import MapContainer from "../MapContainer/MapContainer";

const ContactSection = (props) => {

  const contactData = props.propertyReference.find(el => el.key === 'company-name');

  return (
  <div className="contact-section">
    <div className="map-layer">
      <p className="company-name">{contactData.value}</p>
      <address>
        {contactData.value2}<br/>
        {contactData.value3} <br/>
        {contactData.value4}
      </address>
      <div className="contact-info">
        <span className= "icon-msg" />
        {contactData.value5}
        <a href={`mailto:${contactData.value6}`}>{contactData.value6}</a>
      </div>
      <div className="contact-info">
        <span className="icon-telephone" />
        {contactData.value7}
        <a href={`tel:${contactData.value8}`}>{contactData.value8}</a>
        <a href={`mailto:${contactData.value9}`}>{contactData.value9}</a>
      </div>
    </div>
    <MapContainer isMarkerShown
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDjkSFCjC0R794du7df4xpUrPJCcWFw44c"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `430px` }} />}
                  mapElement={<div style={{ height: `100%` }} />} />
  </div>
)};

export default ContactSection;
