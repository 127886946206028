import React from "react"
import DesktopHeroImage from "../../assets/images/partners-hero.svg"
import MobileHeroImage from "../../assets/images/partners-hero-mobile.svg"
import PartnersContainer from "../PartnersContainer/PartnersContainer"
import { findByKey } from "../../helpers/helpers"
import "./PartnersHero.scss"
import { IPropertyReference } from "../../helpers/interfaces"

const PartnersHero = ({
  propertyReference,
}: {
  propertyReference: IPropertyReference[]
}) => {
  const title = findByKey(propertyReference, "title").value
  const description = findByKey(propertyReference, "description").value
  const callToAction = findByKey(propertyReference, "callToAction")

  return (
    <PartnersContainer>
      <div className="partners-hero">
        <div className="image mobile-image">
          <MobileHeroImage />
        </div>
        <div className="title">
          <h1>{title}</h1>
          <p>{description}</p>
          <a
            onClick={() => {
              document.getElementById("request-contact").scrollIntoView({
                behavior: "smooth",
              })
            }}
            className="btn m20t"
          >
            {callToAction.content}
          </a>
        </div>
        <div className="image desktop-image">
          <DesktopHeroImage />
        </div>
      </div>
    </PartnersContainer>
  )
}

export default PartnersHero
