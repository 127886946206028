import React from "react";
import "./VideoCard.scss";
import { parseHTML } from "../../helpers/parseHTML"
import YouTubePlayer from "../YouTubePlayer/YoutubePlayer"

const VideoCard = (data) => (
  <div className="video-card">
    <div className="react-player">
      <YouTubePlayer url={data.video.url} width="100%" height="100%" controls/>
    </div>
    <div className="video-card-description">
      <h1>{data.title}</h1>
      {parseHTML(data.description)}
    </div>
  </div>
)

export default VideoCard;
