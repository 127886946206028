import React from 'react';
import PartnerCard from "../PartnerCard/PartnerCard"
import './CanJoinSection.scss'


const canJoinSection = ({ propertyReference } ) => {
  const title = propertyReference.find(el => el.key === "title");
  const cards = propertyReference.filter(el => el.key === "description");

  return <div className="can-join-wrapper content-limiter">
    <h1 className="title-section">{title.value}</h1>
    <div className="partners">
      <div className="partners-list">
        {cards.map((card, idx) => {
          return <PartnerCard key={idx} description={card.value} icon={card.content}/>
        })}
      </div>
    </div>
  </div>
}

export default canJoinSection;
