import * as React from "react"
import Layout from "./Layout/Layout"
import { graphql } from "gatsby"

import Awards from "./Awards/Awards"
import { AuthorsBio } from "./AuthorsBio/AuthorsBio"
import BackToTopSign from "./BackToTopSign/BackToTopSign"
import BasicForm from "./BasicForm/BasicForm"
import BasicMenu from "./BasicMenu/BasicMenu"
import BecomingPartnerSection from "./BecomingPartnerSection/BecomingPartnerSection"
import BenefitsPartner from "./BenefitsPartner/BenefitsPartner"
import BenefitsPartnersList from "./BenefitsPartnersList/BenefitsPartnersList"
import BenefitsTitleSection from "./BenefitsTitleSection/BenefitsTitleSection"
import BigIcon from "./BigIcon/BigIcon"
import BigIconsSection from "./BigIconsSection/BigIconsSection"
import { BlogBreadcrumbs } from "./BlogBreadcrumbs/BlogBreadcrumbs"
import BlogPost from "./BlogPost/BlogPost"
import BlogPostCard from "./BlogPostCard/BlogPostCard"
import BusinessUseCaseCard from "./BusinessUseCaseCard/BusinessUseCaseCard"
import { CallToAction } from "./CallToAction/CallToAction"
import ClickToCall from "./ClickToCall/ClickToCall"
import CampaignTypes from "./CampaignTypes/CampaignTypes"
import Carousel from "./Carousel/Carousel"
import CarouselImageWrapper from "./CarouselImageWrapper/CarouselImageWrapper"
import CanJoinSection from "./CanJoinSection/CanJoinSection"
import CaseStudyCard from "./CaseStudyCard/CaseStudyCard"
import CategoryTabs from "./CategoryTabs/CategoryTabs"
import CenteredIntroSection from "./CenteredIntroSection/CenteredIntroSection"
import CompanyInfo from "./CompanyInfo/CompanyInfo"
import Compatibility from "./Compatibility/Compatibility"
import ContactCard from "./ContactCard/ContactCard"
import ContactCardForm from "./ContactCardForm/ContactCardForm"
import ContactForm from "./ContactForm/ContactForm"
import ContactSection from "./ContactSection/ContactSection"
import ContactFormSection from "./ContactFormSection/ContactFormSection"
import DemoRequest from "./DemoRequest/DemoRequest"
import DoubleTextAndImageContainer from "./DoubleTextAndImageContainer/DoubleTextAndImageContainer"
import EBookCards from "./EBookCards/EBookCard"
import EBookAuthors from "./EBookAuthors/EBookAuthors"
import EBookInfo from "./EBookInfo/EBookInfo"
import EBookSummary from "./EBookSummary/EBookSummary"
import EBookThankYou from "./EBookThankYou/EBookThankYou"
import EBookTitleSection from "./EBookTitleSection/EBookTitleSection"
import FeaturesSlider from "./FeaturesSlider/FeaturesSlider"
import FormNewsletter from "./FormNewsletter/FormNewsletter"
import { FundingBanner } from "./FundingBanner/FundingBanner"
import { GreyFooter } from "./GreyFooter/GreyFooter"
import Icon from "./Icon/Icon"
import InfoBox from "./InfoBox/InfoBox"
import IntegrationCode from "./IntegrationCode/IntegrationCode"
import { InterviewForm } from "./InterviewForm/InterviewForm"
import InformationContainer from "./InformationContainer/InformationContainer"
import IntroSection from "./IntroSection/IntroSection"
import JobOffer from "./JobOffer/JobOffer"
import JobOffers from "./JobOffers/JobOffers"
import JobOffersIntro from "./JobOffersIntro/JobOffersIntro"
import MainMenu from "./MainMenu/MainMenu"
import MapContainer from "./MapContainer/MapContainer"
import MediumIcon from "./MediumIcon/MediumIcon"
import NotFoundContent from "./NotFoundContent/NotFoundContent"
import NumberStats from "./NumberStats/NumberStats"
import OurClients from "./OurClients/OurClients"
import OurFunctionalities from "./OurFunctionalities/OurFunctionalities"
import OurTools from "./OurTools/OurTools"
import PartnersFAQ from "./PartnersFAQ/PartnersFAQ"
import PartnerPricing from "./PartnerPricing/PartnerPricing"
import PartnershipPlanCard from "./PartnershipPlanCard/PartnershipPlanCard"
import PartnershipPlansSection from "./PartnershipPlansSection/PartnershipPlansSection"
import PartnershipStep from "./PartnershipStep/PartnershipStep"
import PriceList from "./PriceList/PriceList"
import Pricing from "./Pricing/Pricing"
import PricingLite from "./Pricing/PricingLite"
import PricingLiteFAQ from "./PricingLiteFAQ/PricingLiteFAQ"
import PrivacySection from "./PrivacySection/PrivacySection"
import PrivacySingleInfo from "./PrivacySingleInfo/PrivacySingleInfo"
import ReadTime from "./ReadTime/ReadTime"
import RecentPost from "./RecentPost/RecentPost"
import RecentPosts from "./RecentPosts/RecentPosts"
import RODOQuestions from "./RODOQuestions/RODOQuestions"
import SEOLandingLinks from "./SEOLandingLinks/SEOLandingLinks"
import { SEOMetaTags } from "./SEOMetaTags/SEOMetaTags"
import ShareButtons from "./ShareButtons/ShareButtons"
import SingleBenefit from "./SingleBenefit/SingleBenefit"
import SingleChart from "./SingleChart/SingleChart"
import SingleInformation from "./SingleInformation/SingleInformation"
import SingleStep from "./SingleStep/SingleStep"
import Slider from "./Slider/Slider"
import SnowButton from "./SnowButton/SnowButton"
import Subtitle from "./Subtitle/Subtitle"
import Step from "./Step/Step"
import StrategyPushForm from "./StrategyPushForm/StrategyPushForm"
import StrategyPushFunctions from "./StrategyPushFunctions/StrategyPushFunctions"
import StrategyPushInfo from "./StrategyPushInfo/StrategyPushInfo"
import StrategyPushOpinions from "./StrategyPushOpinions/StrategyPushOpinions"
import StrategyPushUse from "./StrategyPushUse/StrategyPushUse"
import TeamMember from "./TeamMember/TeamMember"
import TeamMembers from "./TeamMembers/TeamMembers"
import Testimonial from "./Testimonial/Testimonial"
import Testimonials from "./Testimonials/Testimonials"
import TestPush from "./TestPush/TestPush"
import { TitleSection } from "./TitleSection/TitleSection"
import TextAndBigImage from "./TextAndBigImage/TextAndBigImage"
import TextAndImageContainer from "./TextAndImageContainer/TextAndImageContainer"
import TextAndMediumIconsContainer from "./TextAndMediumIconsContainer/TextAndMediumIconsContainer"
import TextAndSmallImageContainer from "./TextAndSmallImageContainer/TextAndSmallImageContainer"
import TextBox from "./TextBox/TextBox"
import Tool from "./Tool/Tool"
import ToggleNotifications from "./ToggleNotifications/ToggleNotifications"
import useModal from "./useModal"
import VideoCard from "./VideoCard/VideoCard"
import VideoComponent from "./VideoComponent/VideoComponent"
import WebinarInfo from "./WebinarInfo/WebinarInfo"
import WebinarDetailsInfoWrapper from "./WebinarDetailsInfoWrapper/WebinarDetailsInfoWrapper"
import WhyPPG from "./WhyPPG/WhyPPG"
import WideFooter from "./WideFooter/WideFooter"
import { IPropertyReference } from "../helpers/interfaces"
import RequestContact from "./RequestContact/RequestContact"
import PartnersHero from "./PartnersHero/PartnersHero"
import CurrentPartners from "./CurrentPartners/CurrentPartners"

const components = new Map([
  ["Awards", Awards],
  ["AuthorsBio", AuthorsBio],
  ["BackToTopSign", BackToTopSign],
  ["BasicForm", BasicForm],
  ["BasicMenu", BasicMenu],
  ["BecomingPartnerSection", BecomingPartnerSection],
  ["BenefitsPartner", BenefitsPartner],
  ["BenefitsPartnersList", BenefitsPartnersList],
  ["BenefitsTitleSection", BenefitsTitleSection],
  ["BigIcon", BigIcon],
  ["BigIconsSection", BigIconsSection],
  ["BlogBreadcrumbs", BlogBreadcrumbs],
  ["BlogPost", BlogPost],
  ["BlogPostCard", BlogPostCard],
  ["BusinessUseCaseCard", BusinessUseCaseCard],
  ["CallToAction", CallToAction],
  ["ClickToCall", ClickToCall],
  ["CampaignTypes", CampaignTypes],
  ["Carousel", Carousel],
  ["CarouselImageWrapper", CarouselImageWrapper],
  ["CanJoinSection", CanJoinSection],
  ["CaseStudyCard", CaseStudyCard],
  ["CategoryTabs", CategoryTabs],
  ["CenteredIntroSection", CenteredIntroSection],
  ["CompanyInfo", CompanyInfo],
  ["Compatibility", Compatibility],
  ["ContactCard", ContactCard],
  ["ContactCardForm", ContactCardForm],
  ["ContactForm", ContactForm],
  ["ContactSection", ContactSection],
  ["ContactFormSection", ContactFormSection],
  ["CookiesBar", () => null],
  ["CurrentPartners", CurrentPartners],
  ["DemoRequest", DemoRequest],
  ["DoubleTextAndImageContainer", DoubleTextAndImageContainer],
  ["EBookCards", EBookCards],
  ["EBookAuthors", EBookAuthors],
  ["EBookInfo", EBookInfo],
  ["EBookSummary", EBookSummary],
  ["EBookThankYou", EBookThankYou],
  ["EBookTitleSection", EBookTitleSection],
  ["FeaturesSlider", FeaturesSlider],
  ["FormNewsletter", FormNewsletter],
  ["FundingBanner", FundingBanner],
  ["GreyFooter", GreyFooter],
  ["Icon", Icon],
  ["InfoBox", InfoBox],
  ["InformationContainer", InformationContainer],
  ["IntegrationCode", IntegrationCode],
  ["InterviewForm", InterviewForm],
  ["IntroSection", IntroSection],
  ["JobOffer", JobOffer],
  ["JobOffers", JobOffers],
  ["JobOffersIntro", JobOffersIntro],
  ["Layout", Layout],
  ["MainMenu", MainMenu],
  ["MapContainer", MapContainer],
  ["MediumIcon", MediumIcon],
  ["NotFoundContent", NotFoundContent],
  ["NumberStats", NumberStats],
  ["OurClients", OurClients],
  ["OurFunctionalities", OurFunctionalities],
  ["OurTools", OurTools],
  ["PartnersHero", PartnersHero],
  ["PartnersFAQ", PartnersFAQ],
  ["PartnerPricing", PartnerPricing],
  ["PartnershipStep", PartnershipStep],
  ["PartnershipPlanCard", PartnershipPlanCard],
  ["PartnershipPlansSection", PartnershipPlansSection],
  ["PriceList", PriceList],
  ["Pricing", Pricing],
  ["PricingLite", PricingLite],
  ["PricingLiteFAQ", PricingLiteFAQ],
  ["PrivacySection", PrivacySection],
  ["PrivacySingleInfo", PrivacySingleInfo],
  ["ReadTime", ReadTime],
  ["RequestContact", RequestContact],
  ["RecentPost", RecentPost],
  ["RecentPosts", RecentPosts],
  ["RODOQuestions", RODOQuestions],
  ["SEOLandingLinks", SEOLandingLinks],
  ["SEOMetaTags", SEOMetaTags],
  ["ShareButtons", ShareButtons],
  ["SingleBenefit", SingleBenefit],
  ["SingleChart", SingleChart],
  ["SingleInformation", SingleInformation],
  ["SingleStep", SingleStep],
  ["Slider", Slider],
  ["SnowButton", SnowButton],
  ["Subtitle", Subtitle],
  ["Step", Step],
  ["StrategyPushForm", StrategyPushForm],
  ["StrategyPushFunctions", StrategyPushFunctions],
  ["StrategyPushInfo", StrategyPushInfo],
  ["StrategyPushOpinions", StrategyPushOpinions],
  ["StrategyPushUse", StrategyPushUse],
  ["TeamMember", TeamMember],
  ["TeamMembers", TeamMembers],
  ["Testimonial", Testimonial],
  ["Testimonials", Testimonials],
  ["TestPush", TestPush],
  ["TextAndBigImage", TextAndBigImage],
  ["TextAndImageContainer", TextAndImageContainer],
  ["TextAndMediumIconsContainer", TextAndMediumIconsContainer],
  ["TextAndSmallImageContainer", TextAndSmallImageContainer],
  ["TextBox", TextBox],
  ["TitleSection", TitleSection],
  ["Tool", Tool],
  ["ToggleNotifications", ToggleNotifications],
  ["useModal", useModal],
  ["VideoCard", VideoCard],
  ["VideoComponent", VideoComponent],
  ["WebinarInfo", WebinarInfo],
  ["WebinarDetailsInfoWrapper", WebinarDetailsInfoWrapper],
  ["WhyPPG", WhyPPG],
  ["WideFooter", WideFooter],
])

interface IDatoCMSData {
  data: IImages
  pageContext: IPageContext
}

interface IImages {
  PushIcon1: {
    childImageSharp: {
      fluid: { aspectRatio: number; sizes: string; src: string; srcSet: string }
    }
  }
  PushIcon2: {
    childImageSharp: {
      fluid: { aspectRatio: number; sizes: string; src: string; srcSet: string }
    }
  }
  PushIcon3: {
    childImageSharp: {
      fluid: { aspectRatio: number; sizes: string; src: string; srcSet: string }
    }
  }
  PushIcon4: {
    childImageSharp: {
      fluid: { aspectRatio: number; sizes: string; src: string; srcSet: string }
    }
  }
  PushIcon5: {
    childImageSharp: {
      fluid: { aspectRatio: number; sizes: string; src: string; srcSet: string }
    }
  }
}

interface IPageContext {
  locale: string
  name: string
  subdomain: string
  layout: ILayoutComponent[]
  _allSubdomainLocales: { locale: string; value: string }[]
}

interface ILayoutComponent {
  componentReference: { name: string }
  propertyReference: IPropertyReference[]
}

class PageBuilder {
  private data: IDatoCMSData

  static generatePageComponent(data) {
    const instance = new PageBuilder(data)
    return instance.buildPage()
  }

  static get components() {
    return components
  }

  static resolveImage(image) {
    return image.childImageSharp.fixed || image.childImageSharp.fluid
  }

  constructor(data: IDatoCMSData) {
    this.data = data
  }

  buildPage() {
    const components = []
    const { data, pageContext } = this.data
    for (const component of pageContext.layout) {
      const componentName = component.componentReference.name
      const DynamicComponent = PageBuilder.components.get(componentName)

      if (!DynamicComponent) {
        console.log("Not found component: ", componentName)
        continue
      }

      const images = Object.keys(data).reduce((result, imageName) => {
        result[imageName] = PageBuilder.resolveImage(data[imageName])
        return result
      }, {})

      const payload = {
        ...component,
        ...images,
      }

      components.push(
        <DynamicComponent
          {...payload}
          key={Math.random() + new Date().toDateString()}
        />
      )
    }

    return components
  }
}

const Builder = (data) => {
  const { locale, _allSubdomainLocales } = data.pageContext
  const allLocales = data && _allSubdomainLocales
  const alternateLocaleData =
    allLocales && allLocales.filter((el) => el.locale !== locale)
  const alternateLocale =
    alternateLocaleData.length > 0 && alternateLocaleData[0].locale
  const alternateSlug =
    alternateLocaleData.length > 0 && alternateLocaleData[0].value
  const alternateLink =
    alternateLocaleData.length > 0 && `https://pushpushgo.com${alternateSlug}`
  return (
    <Layout
      alternateLocale={alternateLocale}
      lang={locale}
      alternateLink={alternateLink}
    >
      {PageBuilder.generatePageComponent(data)}
    </Layout>
  )
}

export default Builder

export const query = graphql`
  {
    PushIcon1: file(relativePath: { eq: "push-icons1.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    PushIcon2: file(relativePath: { eq: "push-icons2.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    PushIcon3: file(relativePath: { eq: "push-icons3.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    PushIcon4: file(relativePath: { eq: "push-icons4.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    PushIcon5: file(relativePath: { eq: "push-icons5.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
