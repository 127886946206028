import * as React from "react";
import { useState } from 'react';
import "./PartnersFAQ.scss";
import Subtitle from "../Subtitle/Subtitle";

const PartnersFAQ = (props) => {

  const subtitleData = props.propertyReference.find(el => el.key === "subtitle")
  const faqQuestions = props.propertyReference.filter(el => el.key !== "subtitle")
  const subtitle = subtitleData && subtitleData.value

  return (
    <section className='main '>
      {subtitle && <Subtitle text={subtitle}/>}
      <div className="content-limiter">
        <div className="partners-faq-container">

          {
            faqQuestions && faqQuestions.map(question => <SingleFAQ key={Math.random() + new Date().toDateString()} question={question} />)
          }

        </div>
      </div>
    </section>
  )
}

export default PartnersFAQ

const SingleFAQ = ({ question}) => {
  const [isOpen, setOpen] = useState(false);

  const handleOnKeyDown = e => {
    if (e.keyCode === 13) {
      setOpen(!isOpen)
    }
  }

  return <div className={`faq-wrapper ${isOpen ? 'faq-open' : ''}`} onKeyDown={handleOnKeyDown} tabIndex={0} role={'button'} aria-expanded={isOpen} onClick={() => setOpen(!isOpen)}>
    <div className="faq-question">
      <span>{question.value}</span>
      <div className={`plus-sign-wrapper ${isOpen ? "active" : ""}`}>
        <div className="plus-sign"/>
      </div>
    </div>
    <div role={'region'} className="faq-answer">{question.content}</div>
  </div>
}
