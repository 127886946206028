import * as React from 'react';
import './ToggleNotifications.scss'
import { findByKey } from "../../helpers/helpers"
import Step from "../Step/Step"
import Select from '../Select/Select'
import { useState } from "react"

enum browserType {
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  OPERA = 'Opera',
  EDGE = 'Edge'
}

const options = [
  { value: browserType.CHROME, label: browserType.CHROME },
  { value: browserType.FIREFOX, label: browserType.FIREFOX },
  { value: browserType.OPERA, label: browserType.OPERA },
  { value: browserType.EDGE, label: browserType.EDGE },
]


const ToggleNotifications = ({propertyReference}) => {
  const [ browser, setBrowser ] = useState(browserType.CHROME)
  const title = findByKey(propertyReference, "title");
  const chromeSteps = propertyReference.filter(step => step.key === "chrome-steps");
  const firefoxSteps = propertyReference.filter(step => step.key === "firefox-steps");
  const operaSteps = propertyReference.filter(step => step.key === "opera-steps");
  const edgeSteps = propertyReference.filter(step => step.key === "edge-steps");

  const getBrowserSteps = () => {
    switch (browser){
      case browserType.CHROME: return chromeSteps;
      case browserType.FIREFOX: return firefoxSteps;
      case browserType.OPERA: return operaSteps;
      case browserType.EDGE: return edgeSteps;
    }
  }

  return <div className={'toggle-notifications-wrapper'}>
    <div className={'toggle-notifications-header'}>
      {title.value}
      <div className={"toggle-notifications-header_border"} />
      <div className={'toggle-notifications-header_select'}>
        <Select options={options} onChange={event => setBrowser(event.target.value)}/>
      </div>
    </div>

    <div className='toggle-notifications-body'>
      { getBrowserSteps().map((step, idx) => {
        return <Step number={idx + 1} property={step} isLastItem={idx + 1 === chromeSteps.length}/>
      })}
    </div>
  </div>
}

export default ToggleNotifications;
