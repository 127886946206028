import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./StrategyPushUse.scss";

interface IStrategyPushUseProps {
  propertyReference: IPropertyReference[];
}

const StrategyPushUse: React.FC<IStrategyPushUseProps> = ({ propertyReference }) => {

  const title = findByKey(propertyReference, "strategy-push-use-title").value;
  const subtitle = findByKey(propertyReference, "strategy-push-use-subtitle").value;
  const useItemsList = filterByKey(propertyReference, "strategy-push-use-items");
  const image = findByKey(propertyReference, "strategy-push-use-image");

  const useItems = useItemsList.map((useItem: IPropertyReference, id: number) => {
    return <li key={ id } className="strategy-use__example">{ useItem.value }</li>;
  });

  return <section className="strategy-use">
    <div className="strategy-use__wrapper">
      <h2 className="strategy-use__title">{ title }</h2>

      <div className="strategy-use__box">
        <div>
          <p className="strategy-use__subtitle">{ subtitle }</p>
          <ul className="strategy-use__examples">{ useItems }</ul>
        </div>
      </div>
    </div>

    <div className="strategy-use__image">
      <GatsbyImage alt={ image.image.alt || "" } image={ getImage(image.image.gatsbyImageData) }/>
    </div>
  </section>;
};

export default StrategyPushUse;
