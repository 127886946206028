import * as React from "react"
import "./Pricing.scss"
import { PlanCardTypes, PricingPlanCard } from "../PricingPlanCard/PricingPlanCard"
import { findByKey } from '../../helpers/helpers'

const PricingLite = ({ propertyReference }) => {

  const trailHeader = findByKey( propertyReference, "trial-header")
  const benefits = propertyReference[0];
  const benefitsList = propertyReference[0].value.split(',')
  const plans = propertyReference.filter(property => property.key.indexOf('plan') > -1)
  const standardPlan = plans[0];
  const premiumPlan = plans[1];

  return (
    <section className="pricing-section">
     <span className="trail-header"> {trailHeader.value }</span>
      <div className="pricing-container">
        <div className="lite-benefits-wrapper">
          <table>
            <thead>
            <tr>
              <td>{benefits.value2}</td>
            </tr>
            </thead>
            <tbody>
            {benefitsList.map((benefit, idx) => {
              return <tr key={idx}>
                <td>{benefit}</td>
              </tr>
            })}
            </tbody>
          </table>
        </div>

        <div className="plans-container">
          <PricingPlanCard plan={ standardPlan } labels={benefitsList} type={PlanCardTypes.STANDARD}/>
          <PricingPlanCard plan={ premiumPlan } labels={benefitsList} type={PlanCardTypes.PREMIUM}/>
        </div>
      </div>
    </section>
  )
};

export default PricingLite;
