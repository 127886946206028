import React from "react";
import "./TextAndBigImage.scss";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

const TextAndBigImage = (props) => {
  let imageData = props.propertyReference.find(el => el.image);

  const contentElement = props.propertyReference.find(el => el.content);
  const content = contentElement ? contentElement.content : "";
  const title = props.propertyReference.find(el => el.key === "title");

  return (
    <section className="main text-big-image-container">
      <div className="content-limiter content-container">
        <div className="text-box">
          {title && <h1>{title.value}</h1>}
          <Markdown children={content}/>
        </div>
        <div className="image-box">
          <GatsbyImage alt={imageData.image.alt || ""} image={imageData.image.gatsbyImageData}/>
        </div>
      </div>
    </section>
  );
};

export default TextAndBigImage;
