import React from "react";
import "./OurFunctionalities.scss";
import Markdown from 'react-markdown';
import Subtitle from "../Subtitle/Subtitle";

const OurFunctionalities = (props) => {

  const functionalities = props.propertyReference.filter(el => el.key === 'title');
  const subtitleData = props.propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;

  return (
  <section className="our-functionalities-section">
    <Subtitle text={subtitle} />
    <div className="content-limiter our-functionalities-container">
      {functionalities.map((el) => {
        return <Markdown children={el.value} key={Math.random() + new Date().toDateString()} />;
      })}
    </div>
  </section>
)};

export default OurFunctionalities;
