import * as React from "react"
import "./PartnerPricing.scss"
import useModal from "../useModal"
import ClickToCall from "../ClickToCall/ClickToCall"
import Markdown from "react-markdown"

const PartnerPricing = (props) => {

  const { isShowing, toggle } = useModal()

  const planMonthly = props.propertyReference[0]
  const planAnnual = props.propertyReference[1]
  const planIndividual = props.propertyReference[2]

  return (
    <section className='partner-pricing-section'>

      <div className="content-limiter plans-wrapper">

        <div className="plan">
          <div className="plan-name">
            {planMonthly.value}
          </div>

          <div className="plan-info">
            <span className="plan-price discount">
            <span className="price-amount">{planMonthly.value7}</span>
              <sup>{planMonthly.value3}</sup>
            </span>

            <span className="plan-price">
            <span className="price-amount">{planMonthly.value2}</span>
              <sup>{planMonthly.value3}</sup>
              <span className='price-detail'>/ {planMonthly.value6}</span>
            </span>

            <div className='subscribes-info'>
              <span className='icon-subscribers size20 text-green'/>
              {planMonthly.value8}
            </div>
          </div>

          <div className="plan-includes">
            <Markdown children={planMonthly.content}/>
          </div>

          <a target="_blank" rel="noreferrer" href={planMonthly.value5}
             className="btn">{planMonthly.value4}</a>
        </div>

        <div className="plan raccomended">
          <div className="plan-name">
            {planAnnual.value}
          </div>
          <div className="plan-info">

            <span className="plan-price discount">
            <span className="price-amount">{planAnnual.value7}</span>
              <sup>{planMonthly.value3}</sup>
            </span>

            <span className="plan-price">
            <span className="price-amount">{planAnnual.value2}</span>
              <sup>{planAnnual.value3}</sup>
              <span className='price-detail'>/ {planAnnual.value6}</span>
            </span>
            <div className='subscribes-info'>
              <span className='icon-subscribers size20 text-green'/>
              {planAnnual.value8}
            </div>
          </div>

          <div className="plan-includes">
            <Markdown children={planAnnual.content}/>
          </div>

          <a target="_blank" rel="noreferrer"  href={planAnnual.value5}
             className="btn">{planAnnual.value4}</a>
        </div>

        <div className="plan plan-individual">
          <div className="plan-name">
            {planIndividual.value}
          </div>
          <div className="plan-info">
            <span className="plan-price">
              <span className="price-amount">{planIndividual.value2}</span>
              <sup>{planIndividual.value3}</sup>
            </span>
          </div>

          <div className="plan-includes">
            <Markdown children={planIndividual.content}/>
          </div>

          <div className='contact-us'>
            <div>{planIndividual.value6}</div>
            <a href={`mailto:${planIndividual.value7}`}>{planIndividual.value7}</a>
            <span> {planIndividual.value8}</span>
          </div>

          <a target="_blank" href='/#' rel="noreferrer" onClick={toggle}
             className="btn">{planIndividual.value4}</a>

        </div>

      </div>
      <ClickToCall c2c={props.c2c}
                   lang={props.lang}
                   isShowing={isShowing}
                   hide={toggle}/>
    </section>
  )
}

export default PartnerPricing
