import React from "react";
import "./TextAndImageContainer.scss";
import Markdown from "react-markdown";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";


const TextAndImageContainer = (props) => {
  const details = props.propertyReference;

  const imageData = details.find(el => el.image);

  const greenVersion = details.find(el => el.key === "color");
  const pngImg = details.find(el => el.title3 === "image-type");
  const contentElement = details.find(el => el.content);
  const content = contentElement ? contentElement.content : "";
  const title = details.find(el => el.key === "title");
  const space = props.propertyReference.find(el => el.key === "space");

  const classValue = classNames({
    "green-version": greenVersion && greenVersion.value,
    "png-img": pngImg && pngImg.value3,
    "no-top-space": space && space.value,
  });

  return (
    <section className={`main basic-container ${classValue}`}>
      <div className="content-limiter content-container">
        <div className="text-box">
          {title && <h1>{title.value}</h1>}
          <Markdown children={content}/>
        </div>
        <div className={`image-box ${classValue}`}>
          <GatsbyImage alt={imageData.image.alt || ""} image={imageData.image.gatsbyImageData}/>
        </div>
      </div>
    </section>
  );
};

export default TextAndImageContainer;
