import React from "react"
import "./PartnershipStep.scss"

const PartnershipStep = ({ stepNumber, reverse, children, img, imgSize}) => {

  const rowDirection = reverse ? "-reverse" : "";
  return (
    <div className={`partnership-step${rowDirection}`}>
      <div className={`partnership-step-content${rowDirection}`}>
        <span className="partnership-step-content-number">{stepNumber.toString()}</span>
        <p className="partnership-step-content-description">{children}</p>
        <div className="partnership-step-content-line"/>
      </div>
      <div className={`partnership-step-image ${imgSize && `img-size-${imgSize}`}`}>
        <img src={img} alt=""/>
      </div>
    </div>
  )
}

export default PartnershipStep
