import * as React from "react";
import "./AuthorsBio.scss";
import { IPropertyReference } from '../../helpers/interfaces';
import { filterByKey } from '../../helpers/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface IAuthorsBio {
  propertyReference: IPropertyReference[];
}

export const AuthorsBio = ({ propertyReference }: IAuthorsBio): JSX.Element => {
  const authors = filterByKey(propertyReference, "author");


  return <div className="author-bio__wrapper">
    { authors.map((a, idx) => {
      return <div key={ idx } className="author-item">
        { a.image && <GatsbyImage alt={ a.image.alt || "" } image={ getImage(a.image.gatsbyImageData) || a.image.gatsbyImageData } className="author-item__image"/> }
        <div className="author-item__description">
          { a.value && <p className="author-item__name">{ a.value }</p> }
          { a.value2 && <p className="author-item__job-position">{ a.value2 }</p> }
          { a.title2 && <p className="author-item__email">{ a.title2 }</p> }
          { a.content && <p className="author-item__content">{ a.content }</p> }
        </div>
      </div>
    }) }
  </div>
}
