import * as React from 'react';
import { EmailForm } from '../EmailForm/EmailForm';
import { useEffect, useState } from 'react';
import { regexpEmail } from '../../helpers/regexp';
import { getLanguageByLocation, findByKey } from '../../helpers/helpers';
import Markdown from "react-markdown";
import YouTubePlayer from "../YouTubePlayer/YoutubePlayer"
import "./InterviewForm.scss";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IPropertyReference } from '../../helpers/interfaces';

interface IInterviewForm {
  propertyReference: IPropertyReference[]
}

export const InterviewForm = ({ propertyReference }: IInterviewForm): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [validateMsg, setValidateMsg] = useState<string>("");
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const formBtnSubmit = findByKey(propertyReference, "interview-btn-submit").value;
  const formInput = findByKey(propertyReference, "input-label").value;
  const infoSend = findByKey(propertyReference, "ebook-form-info-send");
  const permissionData = findByKey(propertyReference, "ebook-permission");
  const groupName = findByKey(propertyReference, "interview-group-name").value;
  const errorMessage = findByKey(propertyReference, "error-message").value;
  const interviewTitle = findByKey(propertyReference, "interview-title").value;
  const interviewLink = findByKey(propertyReference, "interview-title").value2;

  const interviewDescription = findByKey(propertyReference, "interview-description").value;

  const interviewHeadingData = findByKey(propertyReference, "interview-heading");
  const interviewHeading = interviewHeadingData && interviewHeadingData.value;
  const interviewSubheading = interviewHeadingData && interviewHeadingData.value2;
  const interviewHeadingDescription = interviewHeadingData && interviewHeadingData.value3;
  const image = interviewHeadingData && getImage(interviewHeadingData.image);

  const permissionInfo = permissionData && permissionData.content || '';
  const permissionLink = permissionData && permissionData.value2 || '';
  const permissionLinkDescription = permissionData && permissionData.value || '';

  useEffect(() => {
    if (isFormSubmitted) {
      setEmail("");
    }
  }, [isFormSubmitted]);

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!validateEmail()) {
      return setValidateMsg(errorMessage || "Invalid e-mail address");
    }

    setValidateMsg("");

    const language = getLanguageByLocation(location);

    fetch("https://api.pushpushgo.com/notification/newsletter/interview", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        language,
        interviewTitle,
        interviewLink,
        groupName
      })
    }).then((res) => {
      setIsFormSubmitted(true);
      window.scrollTo(0,0);
    }).catch(err => {
      console.error("Error: ", err);
    });
  }

  const validateEmail = (): RegExpMatchArray => {
    return email.match(regexpEmail);
  };

  return <div className="interview-form__wrapper">

    { isFormSubmitted && interviewLink && <>
      <h1 className="interview-form__heading--video">{ interviewHeading }</h1>
      <div className="interview-form__video-container">
        <YouTubePlayer url={interviewLink} width="100%" height="100%" controls/>
      </div>
    </> }

    { !isFormSubmitted && <div className="interview-form__header">
      <div className="interview-form__header--content">
        { interviewHeading && <h1 className="interview-form__heading">{ interviewHeading }</h1> }
        { interviewSubheading && <h2>{ interviewSubheading }</h2> }
        { interviewHeadingDescription && <p className="interview-form__header--description">{ interviewHeadingDescription }</p> }
      </div>

      { interviewHeadingData && interviewHeadingData.image && <div className="interview-form__header--image">
        <GatsbyImage alt={ interviewHeadingData.image.alt || "" } image={ image }/>
      </div> }
    </div> }

    <div className="interview-form__content">
      { interviewDescription && <div className="interview-form__description">
        <Markdown children={ interviewDescription } />
      </div> }
      { !isFormSubmitted && <EmailForm setEmail={ setEmail }
                                       formInput={ formInput }
                                       formBtnSubmit={ formBtnSubmit }
                                       handleSubmitForm={ handleSubmitForm }
                                       infoSend={ infoSend }
                                       permissionLink={ permissionLink }
                                       permissionInfo={ permissionInfo }
                                       permissionLinkDescription={ permissionLinkDescription }
                                       validateMsg={ validateMsg }/> }
    </div>
  </div>;
}
