import React from "react";
import "./PrivacySingleInfo.scss";
import Markdown from 'react-markdown';

const PrivacySingleInfo = (singleInfo) => (
  <div className="privacy-info">
    <h2>{singleInfo.value}</h2>
    <span className={`${singleInfo.value2}`} />
    <Markdown children={singleInfo.content} />
  </div>
);

export default PrivacySingleInfo;
