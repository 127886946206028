import * as React from "react"
import { useEffect, useState } from "react"
import { IPropertyReference } from "../../helpers/interfaces"
import { findByKey } from "../../helpers/helpers"
import "./WebinarForm.scss"
import { regexName, regexpEmail } from "../../helpers/regexp"
import PopUp from "../PopUp/PopUp"
import Markdown from "react-markdown"

interface IWebinarFormProps {
  propertyReference: IPropertyReference[]
}

const WebinarForm: React.FC<IWebinarFormProps> = ({ propertyReference }) => {
  const formSubject = findByKey(propertyReference, "webinar-form-subject").value
  const formTitle = findByKey(propertyReference, "webinar-form-title")
  const formName = findByKey(propertyReference, "webinar-form-name")
  const formSurname = findByKey(propertyReference, "webinar-form-surname")
  const formEmail = findByKey(propertyReference, "webinar-form-email")
  const formAgreement = findByKey(propertyReference, "webinar-form-agreement")
  const formButton = findByKey(propertyReference, "webinar-form-button")
  const popUp = findByKey(propertyReference, "webinar-form-popup")

  const [name, setName] = useState<string>("")
  const [surname, setSurname] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [agreement, setAgreement] = useState<boolean>(false)

  const [errName, setErrName] = useState<string>("")
  const [errSurname, setErrSurname] = useState<string>("")
  const [errEmail, setErrEmail] = useState<string>("")

  const [botField, setBotField] = useState<string>("")

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (isFormSubmitted && typeof window !== "undefined") {
      if ((window as any).fbq != null) {
        ;(window as any).fbq("track", "CompleteRegistration")
      }
    }
  }, [isFormSubmitted])

  const encode = (data): string => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const validateForm = (): boolean => {
    formName &&
      (!name.match(regexName)
        ? setErrName(`* ${formName.content}`)
        : setErrName(""))
    formSurname &&
      (!surname.match(regexName)
        ? setErrSurname(`* ${formSurname.content}`)
        : setErrSurname(""))
    formEmail &&
      (!email.match(regexpEmail)
        ? setErrEmail(`* ${formEmail.content}`)
        : setErrEmail(""))

    return !!(
      (formName ? name.match(regexName) : true) &&
      (formSurname ? surname.match(regexName) : true) &&
      (formEmail ? email.match(regexpEmail) : true)
    )
  }

  const validateBotField = (): boolean => {
    return !!botField
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateForm() || validateBotField()) {
      return
    }

    const values = {
      name,
      surname,
      email,
      agreement,
    }

    fetch("/en", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "webinar-form",
        subject: formSubject,
        ...values,
      }),
    })
      .then((res) => {
        setName("")
        setSurname("")
        setEmail("")
        setAgreement(false)
        setIsFormSubmitted(true)
      })
      .catch((err) => {
        setIsFormSubmitted(false)
        console.log("Error: ", err)
      })
  }

  return (
    <section className="webinar-form">
      {formTitle && <h2 className="webinar-form__title">{formTitle.value}</h2>}

      <form
        name="webinar-form"
        className="webinar-form__form"
        onSubmit={handleSubmit}
        data-netlify={true}
        data-netlify-honeypot="bot-field"
        noValidate
      >
        <input type="hidden" id="subject" name="subject" />
        <input type="hidden" name="form-name" value="webinar-form" />
        <input type="hidden" name="bot-field" />
        {formName ? (
          <div className="webinar-form__box-form">
            {formName && (
              <label className="webinar-form__label">{formName.value}</label>
            )}
            <input
              name="name"
              type="text"
              className="webinar-form__input"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
            {errName && (
              <span className="webinar-form__validate-msg">{errName}</span>
            )}
          </div>
        ) : null}
        {formSurname ? (
          <div className="webinar-form__box-form">
            {formSurname && (
              <label className="webinar-form__label">{formSurname.value}</label>
            )}
            <input
              name="surname"
              type="text"
              className="webinar-form__input"
              value={surname}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSurname(e.target.value)
              }
            />
            {errSurname && (
              <span className="webinar-form__validate-msg">{errSurname}</span>
            )}
          </div>
        ) : null}
        {formEmail ? (
          <div className="webinar-form__box-form">
            {formEmail && (
              <label className="webinar-form__label">{formEmail.value}</label>
            )}
            <input
              name="email"
              type="email"
              className="webinar-form__input"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            {errEmail && (
              <span className="webinar-form__validate-msg">{errEmail}</span>
            )}
          </div>
        ) : null}

        <div className="webinar-form__box-form webinar-form__box-form--checkbox">
          <div className="webinar-form__box-input">
            <input
              id="agreement-webinar"
              name="agreement"
              type="checkbox"
              className="webinar-form__input webinar-form__input--checkbox"
              checked={agreement}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAgreement(e.target.checked)
              }
            />
            <span className="webinar-form__input-icon icon-ok-icon" />
          </div>
          {formAgreement && (
            <label
              htmlFor="agreement-webinar"
              className="webinar-form__label webinar-form__label--checkbox"
            >
              {formAgreement.value}
              <Markdown children={formAgreement.content} />
            </label>
          )}
        </div>

        <input
          type="text"
          className="ohnohoney"
          value={botField}
          autoComplete="no-honey"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBotField(e.target.value)
          }
        />

        {formButton && (
          <button type="submit" className="webinar-form__button">
            {formButton.value}
          </button>
        )}

        <PopUp
          show={isFormSubmitted}
          close={() => setIsFormSubmitted(false)}
          closePopUpClickOutside={true}
          title={popUp.value}
          content={popUp.content}
        />
      </form>
    </section>
  )
}

export default WebinarForm
