import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./EBookThankYou.scss";

interface IEBookThankYouProps {
  propertyReference: IPropertyReference[];
}

const EBookThankYou: React.FC<IEBookThankYouProps> = ({ propertyReference }) => {

  const title = findByKey(propertyReference, "ebook-thank-you-title").value;
  const subtitle = findByKey(propertyReference, "ebook-thank-you-subtitle").value;
  const info = findByKey(propertyReference, "ebook-thank-you-info").value;
  const linkInfo = findByKey(propertyReference, "ebook-thank-you-link-info").value;
  const link = findByKey(propertyReference, "ebook-thank-you-link");
  const image = findByKey(propertyReference, "ebook-thank-you-image");
  const btnBack = findByKey(propertyReference, "ebook-thank-you-btn-back");

  return <section className="ebook-thank-you">
    <div className="ebook-thank-you__wrapper">
      <div className="ebook-thank-you__box">
        { title && <h1 className="ebook-thank-you__title">{ title }</h1> }
        { subtitle && <h2 className="ebook-thank-you__subtitle">{ subtitle }</h2> }
        { info && <p className="ebook-thank-you__info">{ info }</p> }
        { linkInfo && <p className="ebook-thank-you__link-info">{ linkInfo }</p> }
        { link && <a href={ link.content } className="ebook-thank-you__link" target="_blank">{ link.value }</a> }
        { btnBack && <a href={ btnBack.content } className="ebook-thank-you__btn-back">{ btnBack.value }</a> }
      </div>

      { image && <div className="ebook-thank-you__box">
        <div className="ebook-thank-you__image">
          <GatsbyImage alt={ image.image.alt || "" } image={ getImage(image.image.gatsbyImageData) }/>
        </div>
      </div> }
    </div>
  </section>;

};

export default EBookThankYou;
