import React from "react";
import "./MediumIcon.scss";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

const MediumIcon = (mediumIcon) => (
  <div className="medium-icon">
    {
      mediumIcon.image.format === "svg"
        ? <img src={mediumIcon.image.url} alt=''/>
        : <GatsbyImage image={mediumIcon.image && mediumIcon.image.gatsbyImageData} alt={mediumIcon.image.alt || ""}/>
    }


    <Markdown children={mediumIcon.value}/>
  </div>
);

export default MediumIcon;
