import * as React from 'react';
import "../EBookInfo/EBookInfo.scss";
import { useState } from 'react';

interface IEmailForm {
  formInput: string;
  validateMsg: string;
  infoSend: {
    value: string;
    content: string;
  };
  formBtnSubmit: string;
  setEmail: (value: string) => void;
  handleSubmitForm: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  permissionInfo: string;
  permissionLink: string;
  permissionLinkDescription: string;
}

export const EmailForm = ({ handleSubmitForm, formInput, validateMsg, setEmail, formBtnSubmit, infoSend, permissionInfo, permissionLink, permissionLinkDescription }: IEmailForm): JSX.Element => {
  const [permission, setPermission] = useState<boolean>(false);

  return <form className="ebook-info__form" onSubmit={ handleSubmitForm }>

    { formInput && <label className="ebook-info__label">{ formInput }</label> }
    <input
      type="text"
      className="ebook-info__input"
      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value) }
    />
    { validateMsg && <span className="ebook-info__validate-msg">{ validateMsg }</span> }

    { formBtnSubmit && <button type="submit"
                               disabled={ !permission }
                               className="ebook-info__btn-submit">{ formBtnSubmit }</button> }

    { permissionInfo && permissionLink && <div className="rodo-permission__wrapper">
      <div className={'rodo-permission__wrapper--input'}>
        <input type="checkbox"
               id={ "permission" }
               className={ 'rodo-permission__wrapper--input-box' }
               onChange={ () => setPermission(!permission) }
               checked={ permission }/>
        <span className="rodo-permission__wrapper--input-icon icon-ok-icon"/>
      </div>
      <label htmlFor="permission">
        { permissionInfo }
        <a target={ "_blank" } href={ permissionLink }>{ permissionLinkDescription }</a>
      </label>
    </div> }

    { infoSend && <p className="ebook-info__info-sent">{ infoSend.value }</p> }
    { infoSend && <p className="ebook-info__info-sent">{ infoSend.content }</p> }
  </form>
}
