import React, { useState } from "react"
import DesktopBackgroundImage from "../../assets/images/current-partners-background.svg"
import MobileBackgroundImage from "../../assets/images/current-partners-background-mobile.svg"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import SwiperType, { Autoplay, Controller } from "swiper"
import PartnersContainer from "../PartnersContainer/PartnersContainer"
import { IPropertyReference } from "../../helpers/interfaces"
import { filterByKey, findByKey } from "../../helpers/helpers"
import useIsMobile from "../useIsMobile"
import { CSSTransition } from "react-transition-group"
import "./CurrentPartners.scss"
import "swiper/css"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import LinkedinIcon from "../../assets/icons/linkedin.svg"
import WebsiteIcon from "../../assets/icons/www.svg"
import CrossIcon from "../../assets/icons/cross.svg"

const truncateText = (text: string, length: number) => {
  return text.length > length
    ? text.replace(new RegExp(`^(.{${length}}[^\\s]*).*$`, "gms"), "$1") + "..."
    : text
}

const Partner = ({
  onClick,
  image,
  content,
  moreInformationText,
}: {
  onClick: () => void
  content: IPropertyReference["content"]
  image: IPropertyReference["image"]
  moreInformationText: string
}) => {
  const isMobile = useIsMobile()

  return (
    <div>
      <div className="partner-image">
        <GatsbyImage
          alt={image.alt || ""}
          image={getImage(image.gatsbyImageData)}
        />
      </div>
      {/** When opening page on mobile width it will
       * trigger hydration warning in development,
       * can't find a way to easily fix this */}
      <ReactMarkdown className="partner-content">
        {isMobile ? truncateText(content, 95) : truncateText(content, 350)}
      </ReactMarkdown>
      <button
        className="partner-button"
        onClick={() => {
          onClick()
        }}
      >
        {moreInformationText}
      </button>
    </div>
  )
}

const PartnerLink = ({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) => {
  return (
    <a rel="noreferrer" target="_blank" href={href}>
      {children}
    </a>
  )
}

const Slider = ({
  setSwiper,
  swiper,
  partners,
  onPartnerSelect,
  moreInformationText,
}: {
  setSwiper: (swiper: SwiperType) => void
  partners: IPropertyReference[]
  swiper: SwiperType
  onPartnerSelect: (index: number) => void
  moreInformationText: string
}) => {
  const isMobile = useIsMobile()

  return (
    <Swiper
      onSwiper={setSwiper}
      className="current-partners-list"
      spaceBetween={isMobile ? 20 : 50}
      loop={true}
      loopedSlides={partners.length}
      slidesPerView={"auto"}
      controller={{
        control: swiper,
      }}
      modules={[Autoplay, Controller]}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      <div className="current-partners-list">
        {partners.map((partner, index) => (
          <SwiperSlide key={partner.image.url} className="partner">
            <Partner
              content={partner.value}
              image={partner.image}
              onClick={() => {
                onPartnerSelect(index)
              }}
              moreInformationText={moreInformationText}
            />
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  )
}

const OpenedPartner = ({
  currentPartner,
  onClose,
}: {
  currentPartner: IPropertyReference
  onClose: () => void
}) => {
  return (
    <div className="partner-open">
      <div className="partner-image">
        <GatsbyImage
          alt={currentPartner.image.alt || ""}
          image={getImage(currentPartner.image.gatsbyImageData)}
        />
      </div>
      <div className="partner-content">
        <ReactMarkdown>{currentPartner.value}</ReactMarkdown>
        <ul className="partner-links">
          {currentPartner.value2 ? (
            <PartnerLink href={currentPartner.value2}>
              <WebsiteIcon className="icon" />
            </PartnerLink>
          ) : null}
          {currentPartner.value3 ? (
            <PartnerLink href={currentPartner.value3}>
              <LinkedinIcon className="icon" />
            </PartnerLink>
          ) : null}
        </ul>
      </div>
      <button
        className="close-button"
        onClick={() => {
          onClose()
        }}
      >
        <CrossIcon />
      </button>
    </div>
  )
}

const CurrentPartners = ({
  propertyReference,
}: {
  propertyReference: IPropertyReference[]
}) => {
  const [swiper, setSwiper] = useState<null | SwiperType>(null)
  const [partnerIndex, setPartnerIndex] = useState<number>(0)
  const [showContent, setShowContent] = useState(false)

  const title = findByKey(propertyReference, "title").value
  const moreInformationText = findByKey(propertyReference, "title").value2
  const partners = filterByKey(propertyReference, "partner")

  const currentPartner = partners[partnerIndex]

  return (
    <div>
      <PartnersContainer>
        <div className="current-partners">
          <div className="background-image">
            <DesktopBackgroundImage className="display-desktop" />
            <MobileBackgroundImage className="display-mobile" />
          </div>
          <div className="title">
            <h2 className="dots">{title}</h2>
          </div>
        </div>
      </PartnersContainer>
      <Slider
        moreInformationText={moreInformationText}
        partners={partners}
        swiper={swiper}
        setSwiper={setSwiper}
        onPartnerSelect={(index) => {
          swiper.allowTouchMove = false
          swiper.autoplay.stop()
          setPartnerIndex(index)
          setShowContent(true)
        }}
      />
      <CSSTransition
        in={showContent}
        timeout={900}
        classNames="partner"
        unmountOnExit
      >
        <OpenedPartner
          onClose={() => {
            swiper.autoplay.start()
            swiper.allowTouchMove = true
            setShowContent(false)
          }}
          currentPartner={currentPartner}
        />
      </CSSTransition>
    </div>
  )
}

export default CurrentPartners
