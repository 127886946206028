import React from "react";
import "./SEOLandingLinks.scss";

const links = [
  {title: "Generowanie leadów"},
  {title: "Generowanie leadów B2B"},
  {title: "Lead nurturing"},
  {title: "Współczynnik konwersji E-commerce"},
  {title: "Jak zwiększyć sprzedaż online?"},
  {title: "Marketing automation"},
  {title: "Ratowanie porzuconych koszyków"},
  {title: "E-mail marketing"},
  {title: "Tworzenie pop-up"},
  {title: "Cross selling"},
  {title: "Up selling"},
]

const SEOLandingLinks = () => {

  return (
    <section className="main">
      <div className="content-limiter wrapper">
        <div className="text-container">
          <h1>Know how</h1>
          <p>Jesteś ciekaw w jaki sposób możesz wykorzystać platformę PushPushGo i jak może ona wpłynąć na Twój biznes? Zapoznaj się z przygotowanymi przez nas artykułami, które przybliżą Ci różne zastosowania powiadomień web push.</p>
        </div>
        <div className="links-container">
          <ul>
            {links.map(link => <li>{link.title}</li>)}
          </ul>
        </div>
      </div>
    </section>
  )};

export default SEOLandingLinks;
