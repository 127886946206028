import React from "react";
import "./WhyPPG.scss";
import SingleBenefit from "../SingleBenefit/SingleBenefit";
import Subtitle from "../Subtitle/Subtitle";

const WhyPPG = (props) => {

  const benefits = props.propertyReference.filter(el => el.key !== 'subtitle');
  const subtitleData = props.propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const noSpace = subtitleData && subtitleData.value2;
  const icon = subtitleData && subtitleData.value3;

return (
  <>
    <Subtitle text={subtitle} icon={icon} color={noSpace} />
    <section className="whyppg-section">
      <div className="content-limiter">
        <ul className="benefits-list">
          {benefits.map((benefit)=> <SingleBenefit {...benefit} key={Math.random() + new Date().toDateString()}/>)}
        </ul>
      </div>
    </section>
  </>
)};

export default WhyPPG;
