import React from "react";
import "./RecentPost.scss";

const RecentPost = (props) => {

  const { lang, backgroundImage, slug, tags, title, btn } = props;
  const allPostsTag = lang && (lang === 'pl' ? 'Wszystkie posty' : 'All posts');
  const tagsWithoutAllPosts = allPostsTag && tags.filter(tag => tag.name !== allPostsTag);
  const displayedTag = tagsWithoutAllPosts && tagsWithoutAllPosts[0] && tagsWithoutAllPosts[0].name;

  return (
  <div className="post-item">
    <div className="post-image" style={{ backgroundImage: `url(${backgroundImage && backgroundImage.url})` }}>
      <a href={`/${lang}/blog/${slug}`} className="post-layer">
       <p className="read-more">{btn}</p>
      </a>
    </div>
    <h2>{displayedTag}</h2>
    <a href={`/${lang}/blog/${slug}`} className="post-description">
      <p>{title}</p>
    </a>
  </div>
)};

export default RecentPost;
