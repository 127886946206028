import * as React from "react";
import "./Awards.scss";
import { IComponent } from "../../helpers/interfaces";
import { GatsbyImage } from "gatsby-plugin-image";


const Awards = ({ propertyReference }: IComponent): JSX.Element => {
  const subtitle = propertyReference.find(el => el.key === "subtitle");
  const awards = propertyReference.filter(el => el.key !== "subtitle");

  return (
    <section className="content-limiter awards-section">
      {subtitle && <h1>{subtitle.value}</h1>}
      <div className="awards-container">
        {awards && awards.map(award => {
          return <div className="award" key={Math.random() + new Date().toDateString()}>
            <GatsbyImage className="award" alt={award.image.alt || ""} image={award.image.gatsbyImageData}/>
            <h2>{award.value}</h2>
            <p>{award.content}</p>
          </div>;
        })}
      </div>
    </section>
  );
};

export default Awards;
