import React from "react";
import "./PriceList.scss";

const PriceList = () => (
 <section className="price-list-section">
    <div className="content-limiter price-list-container">
     <div className="free-test info-box">
       <div className="get-access">
         <h2>Testuj za darmo</h2>
         <span className="icon-access" />
         <button>Uzyskaj dostęp</button>
       </div>
       <div className="content">
         <p>Otrzymasz 14 dni bezpłatnego okresu testowego, bez żadnych zobowiązań</p>
         <p>Będziesz miał dostęp do wszystkich funkcjonalności</p>
         <p>W każdej chwili możesz zrezygnować</p>
       </div>
     </div>
      <div className="contact info-box">
        <div className="contact-us">
          <h2>Skontaktuj się z nami</h2>
          <span className="icon-telephone" />
          <button>Zamów rozmowę</button>
        </div>
        <div className="photo">
          <img src="https://www.datocms-assets.com/11168/1559555281-artur.jpg" alt=""/>
          <p>lub napisz do mnie</p>
          <a href="mailto:artur@pushpushgo.com">artur@pushpushgo.com</a>
        </div>
      </div>
    </div>
  </section>
);

export default PriceList;
