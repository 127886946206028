import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./StrategyPushFunctions.scss";

interface IStrategyPushFunctionsProps {
  propertyReference: IPropertyReference[];
}

const StrategyPushFunctions: React.FC<IStrategyPushFunctionsProps> = ({ propertyReference }) => {
  const title = findByKey(propertyReference, "strategy-push-functions-title").value;
  const functionsList = filterByKey(propertyReference, "strategy-push-functions");

  const functions = functionsList.map((item: IPropertyReference, id: number) => {
    return <div key={ id } className="strategy-functions__function">
      <h3 className="strategy-functions__function-name">{ item.value }</h3>
      <div className="strategy-functions__function-image">
        <GatsbyImage alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) }/>
      </div>
      <p className="strategy-functions__function-description">{ item.content }</p>
    </div>;
  });

  return <section className="strategy-functions">
    <h2 className="strategy-functions__title">{ title }</h2>
    <div className="strategy-functions__functions">{ functions }</div>
  </section>;
};

export default StrategyPushFunctions;
