import PartnershipPlanCard from "../PartnershipPlanCard/PartnershipPlanCard"
import React from "react"
import "./PartnershipPlansSection.scss"

const PartnershipPlansSection = ({propertyReference}) => {

  const sectionTitle = propertyReference.find(el => el.key === "title");
  const plans = propertyReference.filter(el => el.key === "plan");
  const includedItems = propertyReference.filter(el => el.key === "included-items");
  const notIncludedItems = propertyReference.filter(el => el.key === "not-included-items");

  const partnershipPlans = [];
  plans.map((e, idx) => partnershipPlans.push(Array.from([e, includedItems[idx], notIncludedItems[idx]])))

  return <div className="partnership-plans">

    <span className="partnership-plans-title">{sectionTitle.value}</span>
    <div className="plan-cards-wrapper content-limiter">
      {partnershipPlans.map( (plan, idx)  => {
        return <PartnershipPlanCard
          key={idx}
          name={plan[0].value}
          includedItems={plan[1]}
          notIncludedItems={plan[2]}
          commission={plan[0].value2}
        />
      })}
    </div>
  </div>
}

export default PartnershipPlansSection;
