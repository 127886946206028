import React from "react";
import "./Compatibility.scss";

const Compatibility = (props) => {

  const legendInfo = props.propertyReference.filter(el => el.key === 'legend');

return (
  <section className="compatibility-section">
    <div className="content-limiter compatibility-container">
     <div className="compatibility-table">
       <table>
         <thead>
           <tr>
             <th />
             <th>Edge</th>
             <th>Firefox</th>
             <th>Chrome</th>
             <th>Safari</th>
             <th>Opera</th>
           </tr>
         </thead>
         <tbody>
           <tr>
             <th>Desktop Mac Os</th>
             <td />
             <td>44</td>
             <td>42</td>
             <td>15</td>
             <td>42</td>
           </tr>
           <tr>
             <th>Desktop Linux</th>
             <td />
             <td>44</td>
             <td>42</td>
             <td />
             <td>42</td>
           </tr>
           <tr>
             <th>Desktop Windows</th>
             <td>14</td>
             <td>44</td>
             <td>42</td>
             <td />
             <td>42</td>
           </tr>
           <tr>
             <th>Mobile Android</th>
             <td />
             <td>42</td>
             <td>42</td>
             <td />
             <td>42</td>
           </tr>
         </tbody>
       </table>
     </div>
      <div className="legend">
        {legendInfo.map(info => {
          return <div className="legend-element" key={Math.random() + new Date().toDateString()}>
                    <span className={`legend-square ${info.value}`} />
                    <span className="legend-content">{info.content}</span>
                </div>
        })}
      </div>
    </div>
  </section>
)};

export default Compatibility;
