import * as React from "react";
import "./RecentPosts.scss";
import RecentPost from "../RecentPost/RecentPost";
import Subtitle from "../Subtitle/Subtitle";
import BusinessUseCaseCard from "../BusinessUseCaseCard/BusinessUseCaseCard";
import { IComponent, IRecentPost } from "../../helpers/interfaces";

interface IRecentPosts extends IComponent {
  recentPosts: IRecentPost[];
  recentUseCases: IRecentPost[];
  lang: string;
}

const RecentPosts = ({ propertyReference, lang, recentPosts, recentUseCases }: IRecentPosts): JSX.Element => {

  const subtitleData = propertyReference.find(el => el.key === "subtitle");
  const subtitle = subtitleData && subtitleData.value;
  const noSpace = subtitleData && subtitleData.value2;
  const buttonData = propertyReference.find(el => el.key === "btn-content");
  const btnContent = buttonData && buttonData.value;
  const btnPostContent = buttonData && buttonData.value2;
  const buttonUrl = buttonData && buttonData.value3;
  const showPosts = lang !== "es";
  const showUseCases = lang === "es";

  return (
    <>
      {subtitleData && <Subtitle text={subtitle} color={noSpace}/>}
      <section className="recent-posts">
        <div className="content-limiter">
          <div className={`recent-posts-container ${showUseCases ? "recent-use-cases" : ""}`}>
            {showPosts && recentPosts.map(post => (
              <RecentPost key={Math.random() + new Date().toDateString()} {...post} lang={lang}
                          btn={btnPostContent}/>
            ))}
            {
              showUseCases && recentUseCases.map(article => <BusinessUseCaseCard
                key={Math.random() + new Date().toDateString()}
                article={article} lang={lang}/>)
            }
            <div className="button-align">
              <a href={buttonUrl} className="btn">{btnContent}</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentPosts;
