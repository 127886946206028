import React from "react";
import "./EBookCard.scss";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

const EBookCard = (ebook) => {

  const lang = ebook && ebook.lang;
  const btnContent = lang && lang === "pl" ? "Czytaj dalej >" : "Read more >";

  return (
    <div className="e-book-card">
      <a href={ebook.ebookLanding}>
        <GatsbyImage image={ebook.ebookImage.gatsbyImageData}
                     className={"e-book-card-image"}
                     alt={ebook.ebookImage.alt ? ebook.ebookImage.alt : ""}/>
      </a>
      <div className="e-book-card-text">
        <a className="e-book-card-title" href={ebook.ebookLanding} rel="noreferrer"
           target="_blank">{ebook.ebookTitle}</a>
        <Markdown className="e-book-card-description" children={ebook.ebookDescription}/>
        <div className="e-book-card-info">
          <a href={ebook.ebookLanding} rel="noreferrer" className="e-book-button btn" target="_blank">{btnContent}</a>
        </div>
      </div>
    </div>
  );
};

export default EBookCard;
