import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as Markdown from "react-markdown";
import "./WebinarDetailsInfo.scss";

interface IWebinarDetailsInfoProps {
  propertyReference: IPropertyReference[];
}

const WebinarDetailsInfo: React.FC<IWebinarDetailsInfoProps> = ({ propertyReference }) => {

  const detailsTitle = findByKey(propertyReference, "details-title");
  const agendaTitle = findByKey(propertyReference, "details-agenda-title");
  const agendaContent = findByKey(propertyReference, "details-agenda-content");
  const speakersTitle = findByKey(propertyReference, "details-speakers-title");
  const speakersItems = filterByKey(propertyReference, "details-speakers-items");
  const logosList = filterByKey(propertyReference, "logos");
  const partnersTitle = findByKey(propertyReference, "details-partners-title");
  const partnersIconsItems = filterByKey(propertyReference, "details-partners-icons");

  const logos = logosList.map((item: IPropertyReference, id: number): JSX.Element => {
    return item.image &&
      <GatsbyImage key={ id } alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) } className={ `webinar-speaker__logo ${ item.content ? item.content : "" }` }/>;
  });

  const speakers = speakersItems.map((item: IPropertyReference, id: number): JSX.Element => {
    return item.value && item.content && item.image && <div key={ id } className="webinar-speaker">
      <div className="webinar-speaker__image" style={ { backgroundImage: `url(${ item.image.url })` } }></div>
      <p className="webinar-speaker__name">{ item.value }</p>
      <p className={`webinar-speaker__job-position ${ item.value2 ? item.value2 : "" }`}>{ item.content }</p>
      { logos[id] }
    </div>;
  });

  const partnersIcons = partnersIconsItems.map((item: IPropertyReference, id: number): JSX.Element => {
    return item.image &&
      <GatsbyImage key={ id } alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) } className={ `webinar-partners__item ${ item.content ? item.content : "" }` }/>;
  });

  return <section className="webinar-details-info">
    { detailsTitle && <h2 className="webinar-details-info__title">{ detailsTitle.value }</h2> }

    <div className="webinar-details-info__box">
      { agendaTitle && <h3 className="webinar-details-info__subtitle">{ agendaTitle.value }</h3> }
      { agendaContent && <Markdown children={ agendaContent.value } className="webinar-agenda"/> }

      { speakersTitle && <h3 className="webinar-details-info__subtitle">{ speakersTitle.value }</h3> }
      <div className="webinar-speakers">{ speakers }</div>

      { partnersTitle && <h3 className="webinar-details-info__subtitle">{ partnersTitle.value }</h3> }
      <div className="webinar-partners">{ partnersIcons }</div>
    </div>
  </section>;
};

export default WebinarDetailsInfo;
