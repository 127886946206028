import React, { useState } from "react";
import "./SnowButton.scss";

const SnowButton = ({ propertyReference }) => {

  const buttonData = propertyReference && propertyReference.find(el => el.key === "button-content");
  const buttonContent = buttonData && buttonData.value;

  const [isSnowing, setSnowing] = useState(false);

  const handleOnKeyDown = e => {
    if (e.keyCode === 13) {
      setSnowing(!isSnowing);
    }
  };

  return (
    <div aria-hidden={true}>
      <div role={"button"} className="ribbon" tabIndex={0} onKeyDown={handleOnKeyDown}
           onClick={() => setSnowing(!isSnowing)}>
      <span className="ribbon-button-wrapper">
        <span className="button-snowflake-before">
          <span className="button-snowflake">❆</span>
          {buttonContent}
        </span>
        <span className="button-snowflake-after">
          Let it
          <span className="button-snowflake">❆</span>
        </span>
      </span>
      </div>
      {isSnowing && (
        <div className="snowflakes" aria-hidden="true">
          <div className="snowflake">
            ❅
          </div>
          <div className="snowflake">
            ❅
          </div>
          <div className="snowflake">
            ❆
          </div>
          <div className="snowflake">
            ❄
          </div>
          <div className="snowflake">
            ❅
          </div>
          <div className="snowflake">
            ❆
          </div>
          <div className="snowflake">
            ❄
          </div>
          <div className="snowflake">
            ❅
          </div>
          <div className="snowflake">
            ❆
          </div>
          <div className="snowflake">
            ❄
          </div>
        </div>)}
    </div>
  );
};

export default SnowButton;
