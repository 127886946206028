import React from "react";
import "./ShareButtons.scss";
import { StaticImage } from "gatsby-plugin-image";

const ShareButtons = (props) => {
  const url = props && props.url;
  const title = props && props.title;
  return (
    <div className="share-post">
    <ul className="share-buttons">
      <li>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=https://pushpushgo.com${url}&quote=${title}`}
           title="Share on Facebook" rel="noreferrer"
           target="_blank">
          <StaticImage src={'../../assets/images/fb-post.png'} alt="Share on Facebook" layout={'fixed'} width={32} height={32}/>
        </a>
      </li>
      <li>
        <a href={`https://twitter.com/intent/tweet?source=https://pushpushgo.com${url}&text=${title} - https://pushpushgo.com${url}`}
           target="_blank" rel="noreferrer"
           title="Tweet">
          <StaticImage src={'../../assets/images/twitter-post.png'} alt="Tweet" layout={'fixed'} width={32} height={32}/>
        </a>
      </li>
      <li>
        <a rel="noreferrer"
          href={`http://www.linkedin.com/shareArticle?mini=true&url=https://pushpushgo.com${url}&title=${title}&summary=&source=https://pushpushgo.com${url}`}
          target="_blank" title="Share on LinkedIn">
          <StaticImage alt="Share on LinkedIn" layout={'fixed'} width={32} height={32} src={'../../assets/images/lin-post.png'}/>
        </a>
      </li>
      <li>
        <a href={`https://getpocket.com/save?url=https://pushpushgo.com${url}&title=${title}`} target="_blank"
           title="Add to Pocket" rel="noreferrer">
          <StaticImage alt="Add to Pocket" layout={'fixed'} width={32} height={32} src={'../../assets/images/pocket-post.png'}/>
        </a>
      </li>
    </ul>
  </div>
  )};

export default ShareButtons;
