import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import "./WebinarInfo.scss";

interface IWebinarInfoProps {
  propertyReference: IPropertyReference[];
}

const WebinarInfo: React.FC<IWebinarInfoProps> = ({ propertyReference }) => {

  const termTitle = findByKey(propertyReference, "term-title");
  const termContentItems = filterByKey(propertyReference, "term-content");
  const organizerTitle = findByKey(propertyReference, "organizer-title");
  const logosList = filterByKey(propertyReference, "logos");


  const termContents = termContentItems.map((item: IPropertyReference, id: number): JSX.Element => {
    return item.value && item.image && <div className="term-content" key={ id }>
      <GatsbyImage alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) } className="term-content__icon"/>
      <p className="term-content__title">{ item.value }</p>
    </div>;
  });

  const logos = logosList.map((item: IPropertyReference, id: number): JSX.Element => {
    return item.image && <GatsbyImage key={ id } alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) } className={ `organizers-icons__item ${ item.content ? item.content : "" }` }/>;
  });

  return <div className="webinar-info">
    <section className="webinar-info__box">
      {termTitle && <h2 className="webinar-info__box-title">{ termTitle.value }</h2>}
       <div className="term-contents">{ termContents }</div>
    </section>

    <section className="webinar-info__box">
      {organizerTitle && <h2 className="webinar-info__box-title">{ organizerTitle.value }</h2>}
      <div className="organizers-icons">{ logos }</div>
    </section>
  </div>;
};

export default WebinarInfo;
