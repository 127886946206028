import * as React from "react";
import { useEffect, useState } from "react";
import "./FormNewsletter.scss";
import { IFormNewsletter } from '../../helpers/interfaces';
import { regexName, regexpEmail } from '../../helpers/regexp';

interface IFormNewsletterProps {
  formNewsletter: IFormNewsletter;
}

const FormNewsletter = ({ formNewsletter }: IFormNewsletterProps) => {

  if (!formNewsletter) {
    return null;
  }

  const data = formNewsletter[0];

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [errName, setErrName] = useState<string>("");
  const [errEmail, setErrEmail] = useState<string>("");

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (isFormSubmitted) {
      setTimeout(() => {
        setIsFormSubmitted(false);
      }, 5000);
    }
  }, [isFormSubmitted]);

  const validateForm = (): boolean => {
    !name.match(regexName) ? setErrName(`* ${ data.inputNameErr }`) : setErrName("");
    !email.match(regexpEmail) ? setErrEmail(`* ${ data.inputEmailErr }`) : setErrEmail("");

    return !!(name.match(regexName) && email.match(regexpEmail));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    fetch("https://api.pushpushgo.com/notification/newsletter", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        groupName: data.groupName
      })
    }).then((res) => {
      setName("");
      setEmail("");
      setIsFormSubmitted(true);
    }).catch(err => {
      console.log("Error: ", err);
    });
  };

  return data && <aside className="form-newsletter">
    {data.title && <h3 className="form-newsletter__title">{ data.title }</h3>}
    {data.image && <img src={ data.image.url } alt="newsletter and mousedeer" className="form-newsletter__image"/>}
    {data.subtitle && <p className="form-newsletter__subtitle">{ data.subtitle }</p>}

    <form className="form-newsletter__form" onSubmit={ handleSubmit } noValidate>
      <input
        type="text"
        className="form-newsletter__input"
        placeholder={ data.inputName }
        value={ name }
        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value) }
      />
      { errName && <span className="form-newsletter__validate-msg">{ errName }</span> }

      <input
        type="email"
        className="form-newsletter__input"
        placeholder={ data.inputEmail }
        value={ email }
        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value) }
      />
      { errEmail && <span className="form-newsletter__validate-msg">{ errEmail }</span> }

      {data.submitBtn && <button type="submit" className="form-newsletter__btn-submit">{ data.submitBtn }</button>}

      { isFormSubmitted && <span className="form-newsletter__congratulations">{ data.congratulations }</span> }
    </form>

    {data.agreement && <p className="form-newsletter__agreement">{ data.agreement }</p>}
  </aside>;
};

export default FormNewsletter;
