import React from "react";
import "./IntroSection.scss";
import Icon from "../Icon/Icon";
import Markdown from 'react-markdown';
import BasicForm from "../BasicForm/BasicForm";
import { StaticImage } from "gatsby-plugin-image";

const IntroSection = (props) => {
  const icons = props.propertyReference.filter(el => el.content === "icon");
  const text = props.propertyReference.find(el => el.content === "text");
  const btnContent = props.propertyReference.find(el => el.key === "btn-content");

  return (
    <section className="intro-page">
      <div className="intro-content content-limiter">
        <div className="text-intro">
          <div className="text-box">
            <Markdown children={text.value} />
            <Markdown children={text.value2}/>
          </div>
          <BasicForm {...btnContent} />
        </div>
        <div className="image-container">
          <div className="animate-area">
            <div className="laptop-image">
              <StaticImage src={'../../assets/images/laptop.png'} layout={'constrained'} width={415} alt={'Image of laptop'} />
              <div className="push-fake push-desktop">
                <div className="push-icon">
                  <span className="icon-bell" />
                </div>
              </div>
            </div>
            <div className="iphone-image">
              <StaticImage src={'../../assets/images/iphone.png'} width={100} layout={'constrained'} alt={'Image of mobile phone'}/>
              <div className="push-fake push-mobile">
                <div className="push-icon">
                  <span className="icon-bell" />
                </div>
              </div>
            </div>
          </div>
          <div className="icons-container">
            {icons.map((icon, idx) => <Icon icon={icon} key={idx}/>)}
          </div>
        </div>
      </div>
    </section>
    )
};

export default IntroSection;
