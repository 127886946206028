import * as React from "react";
import { useState } from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey, getLanguageByLocation } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { regexName, regexpEmail, regexpPage } from '../../helpers/regexp';
import { useLocation } from '@reach/router';
import PopUp from '../PopUp/PopUp';
import "./StrategyPushForm.scss";

interface IStrategyPushFormProps {
  propertyReference: IPropertyReference[];
}

const StrategyPushForm: React.FC<IStrategyPushFormProps> = ({ propertyReference }) => {
  const location = useLocation();

  const background = findByKey(propertyReference, "strategy-push-form-bg");
  const ppgLogo = findByKey(propertyReference, "strategy-push-form-logo");
  const title = findByKey(propertyReference, "strategy-push-form-title").value;
  const benefitsIcon = findByKey(propertyReference, "strategy-push-form-benefits-icon");
  const benefitsList = filterByKey(propertyReference, "strategy-push-form-benefits");
  const subtitle = findByKey(propertyReference, "strategy-push-form-subtitle").value;
  const formName = findByKey(propertyReference, "strategy-push-form-name");
  const formEmail = findByKey(propertyReference, "strategy-push-form-email");
  const formPage = findByKey(propertyReference, "strategy-push-form-page");
  const submitButton = findByKey(propertyReference, "strategy-push-form-btn").value;
  const info = findByKey(propertyReference, "strategy-push-form-info");
  const popUp = findByKey(propertyReference, "strategy-push-form-popup");
  const groupName = findByKey(propertyReference, "strategy-push-form-group-name").value;

  const benefits = benefitsList.map((benefit: IPropertyReference, id: number) => {
    return <div key={ id } className="strategy-form__benefit">
      <div className="strategy-form__benefit-image">
        <GatsbyImage alt={ benefitsIcon.image.alt || "" } image={ getImage(benefitsIcon.image.gatsbyImageData) }/>
      </div>
      <p className="strategy-form__benefit-name">{ benefit.value }</p>
    </div>;
  });

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [page, setPage] = useState<string>("");

  const [errName, setErrName] = useState<string>("");
  const [errEmail, setErrEmail] = useState<string>("");
  const [errPage, setErrPage] = useState<string>("");

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const validateForm = (): boolean => {
    !name.match(regexName) ? setErrName(`* ${ formName.content }`) : setErrName("");
    !email.match(regexpEmail) ? setErrEmail(`* ${ formEmail.content }`) : setErrEmail("");
    !page.match(regexpPage) ? setErrPage(`* ${ formPage.content }`) : setErrPage("");

    return !!(name.match(regexName) && email.match(regexpEmail) && page.match(regexpPage));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const language = getLanguageByLocation(location);

    if (!validateForm()) {
      return;
    }

    fetch("https://api.pushpushgo.com/notification/newsletter/strategy", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        name,
        language,
        url: page,
        groupName
      })
    }).then((res) => {
      setName("");
      setEmail("");
      setPage("");
      setIsFormSubmitted(true);
    }).catch(err => {
      console.log("Error: ", err);
    });
  };

  return <section className="strategy-form" style={ { backgroundImage: `url(${ background.image.url })` } }>
    <div className="strategy-form__box">
      <div className="strategy-form__logo">
        <GatsbyImage alt={ ppgLogo.image.alt || "" } image={ getImage(ppgLogo.image.gatsbyImageData) }/>
      </div>
      <h1 className="strategy-form__title">{ title }</h1>
      <div className="strategy-form__benefits">{ benefits }</div>

      <p className="strategy-form__subtitle">{ subtitle }</p>

      <form className="strategy-form__form" onSubmit={ handleSubmit } noValidate>
        <div className="strategy-form__form-box">
          <label className="strategy-form__label">{ formName.value }</label>
          <input
            type="text"
            value={ name }
            className="strategy-form__input"
            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value) }
          />
          { errName && <span className="strategy-form__validate-msg">{ errName }</span> }
        </div>

        <div className="strategy-form__form-box">
          <label className="strategy-form__label">{ formEmail.value }</label>
          <input
            type="email"
            value={ email }
            className="strategy-form__input"
            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value) }
          />
          { errEmail && <span className="strategy-form__validate-msg">{ errEmail }</span> }
        </div>

        <div className="strategy-form__form-box">
          <label className="strategy-form__label">{ formPage.value }</label>
          <input
            type="text"
            value={ page }
            className="strategy-form__input"
            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setPage(e.target.value) }
          />
          { errPage && <span className="strategy-form__validate-msg">{ errPage }</span> }
        </div>

        <button type="submit" className="strategy-form__btn-submit">{ submitButton }</button>
      </form>

      <p className="strategy-form__form-info">{ info.value }</p>
      <p className="strategy-form__form-info">{ info.content }</p>
    </div>

    <PopUp
      show={ isFormSubmitted }
      close={ () => setIsFormSubmitted(false) }
      closePopUpClickOutside={ true }
      title={ popUp.value }
      content={ popUp.content }
    />
  </section>;
};

export default StrategyPushForm;
