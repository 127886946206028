import React from "react";
import "./BlogPostCard.scss";
import { slugify } from "../../helpers/helpers";
import ReadTime from "../ReadTime/ReadTime";
import { GatsbyImage } from "gatsby-plugin-image";

const BlogPostCard = (props) => {

  const { backgroundImage, title, sneakPeak, publishDate, content, url, tags } = props.post;

  const postTags = [].concat(tags);
  const lang = props.lang;
  const btnContent = lang && lang === "pl" ? "Czytaj dalej >" : "Read more >";

  return (
    <div className="blog-post-item">
      <a href={url}>
        <GatsbyImage className="blog-post-item-image" image={backgroundImage.gatsbyImageData}
                     alt={backgroundImage.alt || ""}/>
      </a>
      <div className="blog-post-item-text">
        <div>
          {postTags && postTags.map(tag => <a href={`/${lang}/blog/category/${slugify(tag.name)}/1`}
                                              key={Math.random() + new Date().toDateString()}
                                              className="post-tag">{tag.name}</a>)}
        </div>
        <a className="blog-post-item-title" href={url}>{title}</a>
        {sneakPeak && <p className="blog-post-item-description">{sneakPeak}</p>}
        <div className="blog-post-item-info">
          <div>
            <span className="blog-post-item-date">{publishDate}</span>
            <span className="read-time">
            <span className="icon-stopwatch"/>
            <ReadTime content={content}/>
          </span>
          </div>
          <div>
            <a href={url} className="btn blog-post-item-btn">{btnContent}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
