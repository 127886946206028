import React from "react";
import "./CampaignTypes.scss";
import Subtitle from "../Subtitle/Subtitle";
import { GatsbyImage } from "gatsby-plugin-image";

const CampaignTypes = (props) => {

  const campaigns = props.propertyReference.filter(el => el.key !== "subtitle");
  const subtitleData = props.propertyReference.find(el => el.key === "subtitle");
  const subtitle = subtitleData && subtitleData.value;
  const color = subtitleData && subtitleData.value2;

  return (
    <section className="campaign-types-section">
      <Subtitle text={subtitle} color={color}/>
      <div className="content-limiter campaign-types-container">
        {campaigns.map((type) => {
          return <div className="campaign-type" key={Math.random() + new Date().toDateString()}>
            <div className="campaign-type-image">
              <GatsbyImage image={type.image.gatsbyImageData} alt={type.image.alt || ""}/>
            </div>
            <div className="campaign-type-content">
              <h2>{type.value}</h2>
              <p>{type.content}</p>
              <a className="campaign-type-button" href={type.value3}>{type.value2}</a>
            </div>
          </div>;
        })}
      </div>
    </section>
  );
};

export default CampaignTypes;
