import React, { useState } from "react";
import Testimonial from "../Testimonial/Testimonial";
import "./Slider.scss";

const Slider = () => {
  const slides = [];
  const [ currentSlideIndx, setCurrentSlideIndx] = useState(0);
  const [ currentSlide, setCurrentSlide ] = useState(slides[0]);

  const previousSlide = () => {
    const resetToEnd = currentSlideIndx === 0;
    const index = resetToEnd ? slides.length - 1 : currentSlideIndx - 1;
    setCurrentSlideIndx(index);
    setCurrentSlide(slides[index]);
  }

  const nextSlide = () => {
    const resetIndx = currentSlideIndx === slides.length - 1;
    const index = resetIndx ? 0 : currentSlideIndx + 1;
    setCurrentSlideIndx(index);
    setCurrentSlide(slides[index]);
  }

  return (
    <>
      <button className="arrow" onClick={previousSlide}>
        <span className="previous icon-arrow-left" />
      </button>
          <div className="slider">
            <Testimonial currentSlide={currentSlide} />
          </div>
      <button className="arrow" onClick={nextSlide}>
        <span className="next icon-arrow-right" />
      </button>
    </>
  )
};

export default Slider;
