import React from "react"
import "./PartnershipPlanCard.scss"

const convertPlanItems = (plan) => {
  return Object.entries(plan).filter(e => { return e[0].includes("value") && e[1] });
}

const PartnershipPlanCard = ({ includedItems, notIncludedItems, commission, name }) => {
  return (
    <div className="plan-card">
      <div className="plan-card-title">
        <h3>{name}</h3>
      </div>
      <div className="plan-card-commission">
        <span className="plan-card-commission-content">
          <span className="plan-card-commission-content-value">{commission}%</span>
          commission
        </span>
      </div>
      <div className="plan-card-benefits">
        {convertPlanItems(includedItems).map( (item, idx) => {
          return <div className="plan-card-benefits-item" key={idx}>
            <img className="item-icon" src={includedItems.image !== null ? includedItems.image.url : ''}  alt="+"/>
            <p className="plan-card-benefits-item-label">{item[1]}</p>
          </div>
        })}
        {notIncludedItems && convertPlanItems(notIncludedItems).map( (item, idx) => {
          return <div className="plan-card-benefits-item" key={idx}>
            <img className="item-icon" src={notIncludedItems.image !== null ? notIncludedItems.image.url : ''}  alt="-"/>
            <p className="plan-card-benefits-item-label">{item[1]}</p>
          </div>
        })}
      </div>
    </div>
  )
}

export default PartnershipPlanCard
