import React from "react";
import "./DoubleTextAndImageContainer.scss";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

const DoubleTextAndImageContainer = ({ propertyReference }) => (
  <section className="main">
    <div className="content-limiter items-container">
      {propertyReference.map((el) => {
        return <div className="content-wrapper" key={Math.random() + new Date().toDateString()}>
          <div className={`text-container ${el.title3 === "text-align" && el.value3}`}>
            <Markdown children={el.content}/>
          </div>
          <div className="image-box">
            <GatsbyImage image={el.image.gatsbyImageData} alt={el.image.alt || ""}/>
          </div>
        </div>;
      })}
    </div>
  </section>
);

export default DoubleTextAndImageContainer;
