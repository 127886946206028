import React from "react"
import "./PartnerCard.scss"

const PartnerCard = ({icon, description}) => {
  return <div className="partner-card-wrapper">
    <span className={`${icon} icon-frame icon-size--3 `} />
    <p className="partner-card-description">{description}</p>
  </div>
}
export default PartnerCard;
