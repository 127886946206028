import React from "react";
import "./Icon.scss";

const Icon = ({ icon }) => (
  <span className={icon.value2}>
     <span className={icon.value} aria-hidden="true" />
  </span>
);

export default Icon;
