import React from "react";
import "./Tool.scss";

const Tool = (tool) => (
  <li className="single-tool">
    <h4>{tool.value}</h4>
    <p>{tool.content}</p>
    <span className="feature-icon">
      <span className={`icon ${tool.value2}`} />
    </span>
  </li>
);

export default Tool;
