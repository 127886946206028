import React from "react";
import "./BigIconsSection.scss";
import BigIcon from "../BigIcon/BigIcon";

const BigIconsSection = ({ propertyReference }) => (
  <section className="main grey-bg">
    <div className="content-limiter big-icons-section">
      {propertyReference.map((icon) => <BigIcon {...icon} key={Math.random() + new Date().toDateString()} />)}
    </div>
  </section>
);

export default BigIconsSection;
