import * as React from "react"
import './PricingLiteFAQ.scss'
import Accordion from "../Accordion/Accordion"

import {findByKey} from "../../helpers/helpers"

const PricingLiteFAQ = ({ propertyReference } ) => {

  const title = findByKey(propertyReference, "title").value;
  const contact = findByKey(propertyReference,"contact");
  const accordions = propertyReference.filter(el => el.key.includes('accordion'))

  return (
    <div className='pricing-lite-faq-wrapper'>
      <div className={'lite-faq-title'}>{title}</div>
      <div className={'separator'} />

      <div className={'faq-body-wrapper'}>
        {accordions.map(propertyReference => {
          return <Accordion property={propertyReference}/>
        })}
      </div>

      <div className={'faq-footer-wrapper'}>
        <span className='faq-footer-title'>{contact.value}</span>
        <span className='faq-footer-subtitle'>{contact.value2}</span>
        <span className='contact-mail'><a href={`mailto:${contact.value5}`}>{contact.value5}</a></span>
      </div>

    </div>
  )
};

export default PricingLiteFAQ;
