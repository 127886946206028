import React from "react"
import Subtitle from "../Subtitle/Subtitle"
import ContactCardForm from "../ContactCardForm/ContactCardForm"
import ContactForm from "../ContactForm/ContactForm"
import "./ContactFormSection.scss"

const ContactFormSection = ({ propertyReference }) => {
  const card = propertyReference.find(el => el.key === "card")
  const form = propertyReference.find(el => el.key === "form")
  const title = propertyReference.find(el => el.key === "title")
  const validators = propertyReference.find(el => el.key === "validators")
  const placeholders = propertyReference.find(el => el.key === "placeholders")

  return <div className="partnership-contact-section">
    <Subtitle text={title.value} fontSize="s" />
    <div className="contact-cards-wrapper content-limiter">
      <ContactCardForm card={card} />
      <ContactForm form={form} validators={validators} placeholders={placeholders}/>
    </div>
  </div>
}

export default ContactFormSection
