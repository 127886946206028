import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import { findByKey, filterByKey } from "../../helpers/helpers";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "./StrategyPushInfo.scss";

interface IStrategyPushInfoProps {
  propertyReference: IPropertyReference[];
}

const StrategyPushInfo: React.FC<IStrategyPushInfoProps> = ({ propertyReference }) => {

  const title = findByKey(propertyReference, "strategy-push-info-title").value;
  const description = findByKey(propertyReference, "strategy-push-info-description").value;
  const image = findByKey(propertyReference, "strategy-push-info-image");
  const itemsInfoList = filterByKey(propertyReference, "strategy-push-info-items");

  const itemsInfo = itemsInfoList.map((item: IPropertyReference, id: number) => {
    return <div key={ id } className="strategy-info__item">
      <div className="strategy-info__item-icon">
        <GatsbyImage alt={ item.image.alt || "" } image={ getImage(item.image.gatsbyImageData) }/>
      </div>
      <p className="strategy-info__item-name">{ item.value }</p>
    </div>;
  });

  return <section className="strategy-info">
    <h2 className="strategy-info__title">{ title }</h2>
    <p className="strategy-info__description">{ description }</p>

    <div className="strategy-info__wrapper">
      <GatsbyImage alt={ image.image.alt || "" } image={ getImage(image.image.gatsbyImageData) } className="strategy-info__image"/>
      <div className="strategy-info__items">{ itemsInfo }</div>
    </div>
  </section>;
};

export default StrategyPushInfo;
