import React from "react";
import "./ContactCard.scss";
import Subtitle from "../Subtitle/Subtitle";

const ContactCard = (props) => {

  const subtitleData = props.propertyReference.find(el => el.key === "subtitle");
  const subtitle = subtitleData && subtitleData.value;
  const noSpaces = subtitleData && subtitleData.value2;
  const contactDetails = props.propertyReference.find(el => el.key === 'title');

  return (
    <>
      {subtitleData && <Subtitle text={subtitle} color={noSpaces}/>}
      <div className="contact-card-container content-limiter">
        <div className="contact-card-avatar">
          <img src={contactDetails.image.url} alt={contactDetails.value}/>
        </div>
        <p>{contactDetails.value}</p>
        <p>{contactDetails.value2}</p>
        <p>{contactDetails.value3}</p>
      </div>
    </>
  )};

export default ContactCard;
