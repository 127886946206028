import React from "react";
import "./BigIcon.scss";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

const BigIcon = ({ image, value }) => (
  <div className="text-icon-container">
    <GatsbyImage alt={image.alt || ""} image={image && image.gatsbyImageData}/>
    <Markdown children={value}/>
  </div>
);

export default BigIcon;
