import * as React from "react";
import { IPropertyReference } from '../../helpers/interfaces';
import WebinarDetailsInfo from '../WebinarDetailsInfo/WebinarDetailsInfo';
import WebinarForm from '../WebinarForm/WebinarForm';
import "./WebinarDetailsInfoWrapper.scss"

interface IWebinarDetailsInfoWrapperProps {
  propertyReference: IPropertyReference[];
}

const WebinarDetailsInfoWrapper: React.FC<IWebinarDetailsInfoWrapperProps> = ({ propertyReference }) => {
  return (
    <div className="webinar-details-info-wrapper">
      <WebinarDetailsInfo propertyReference={ propertyReference }/>
      <WebinarForm propertyReference={ propertyReference }/>
    </div>
  );
};

export default WebinarDetailsInfoWrapper;
