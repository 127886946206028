import React from "react";
import "./TeamMember.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const TeamMember = (member) => (
  <div className="team-member">
    <GatsbyImage className="image-normal" alt={member.fullName} image={member.coverimage.gatsbyImageData}/>
    <GatsbyImage className="image-funny" alt={member.fullName} image={member.hoverimage.gatsbyImageData}/>
    <p className="name">{member.fullName}</p>
    <p className="position">{member.role}</p>
  </div>
);

export default TeamMember;
