import React from 'react';
import "./InformationContainer.scss";
import { parseHTML } from "../../helpers/parseHTML"

const InformationContainer = (props) => {

  const contentData = props && props.propertyReference.find(el => el.contentHtml);
  const content = contentData && contentData.contentHtml;

  return (
    <section className="information-containter-section">
      <div className="content-limiter">
        <article className="information-containter-article">
          {content && parseHTML(content)}
        </article>
      </div>
    </section>
  )
}

export default InformationContainer;
