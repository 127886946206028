import React from "react";
import "./IntegrationCode.scss";
import { StaticImage } from "gatsby-plugin-image";
import Subtitle from "../Subtitle/Subtitle";

const IntegrationCode = (props)  => {

  const subtitleData = props.propertyReference.find(el => el.key === 'subtitle');
  const subtitle = subtitleData && subtitleData.value;
  const color = subtitleData && subtitleData.value2;
  const btnContent = props.propertyReference.find(el => el.key === 'btn-content');

  return (
  <section className="integration-code-section grey-bg">
    <Subtitle text={subtitle} color={color}/>
    <div className="content-limiter integration-code-container">
      <div className="image-container">
        <StaticImage src={'../../assets/images/paste-code.png'} layout={'constrained'} width={850} alt={'Example of integration code'}/>
      </div>
      <a href={btnContent.value2} className="btn btn--medium">{btnContent.value}</a>
    </div>
  </section>
)};

export default IntegrationCode;
